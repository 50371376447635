import React from 'react';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';

const TableBodyBankAccount: React.FC<IBankAccount> = ({
	beneficiary_name,
	beneficiary_country,
	beneficiary_address,
	bank_address,
	bank_name,
	bank_country,
	swift_bic,
	account_number,
	note,
}) => (
	<div className="payment-methods-body">
		<div className="payment-methods-list">
			<div className="payment-methods-item">
				<p>Currency</p>
				<span className="coin coin--type8">
					<span className="coin__icon">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<g clipPath="url(#clip0_847_169045)">
								<path
									d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
									fill="#FFDA44"
								/>
								<path
									d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
									fill="#338AF3"
								/>
							</g>
							<defs>
								<clipPath id="clip0_847_169045">
									<rect width="24" height="24" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</span>
					<div className="coin__wrap">
						<span className="coin__text coin__text---upper">UAH</span>
						<span className="coin__text-more">Ukraine Hryvnia</span>
					</div>
				</span>
			</div>
			<div className="payment-methods-item">
				<p>Beneficiary Name</p>
				<span>{beneficiary_name}</span>
			</div>
			<div className="payment-methods-item">
				<p>Beneficiary Bank</p>
				<span>{bank_name}</span>
			</div>
			<div className="payment-methods-item payment-methods-item--text-right">
				<p>Account Number</p>
				<span>{account_number}</span>
			</div>
			{/* <div className="payment-methods-item">
				<p>Beneficiary Address</p>
				<span>{beneficiary_address}</span>
			</div>
			<div className="payment-methods-item">
				<p>Beneficiary Country</p>
				<span>{beneficiary_country}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Address</p>
				<span>{bank_address}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Country</p>
				<span>
					{' '}
					{bank_country === ' Caribbean Netherlands' ? '🇧🇶 Caribbean Netherlands' : bank_country}
				</span>
			</div>
			<div className="payment-methods-item">
				<p>SWIFT / BIC</p>
				<span>{swift_bic}</span>
			</div> */}
		</div>
		{/* <div className="payment-methods-list payment-methods-list--type2">
			<div className="payment-methods-item">
				<p>Notes</p>
				<span>{note}</span>
			</div>
		</div> */}
	</div>
);

export default TableBodyBankAccount;
