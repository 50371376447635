export const calculatePercent = (percent: number, num: number) => {
	const perc = (percent / 100) * num;
	return Number(perc);
};

export const calculatePercentReverse = (percent: number, num: number) => {
	const step1 = 100 - Number(percent);
	const step2 = Number(num) / Number(step1);
	const step3 = Number(step2) * Number(percent);
	return Number(step3);
};
