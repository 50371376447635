export type IRoundingNumber = (
	number?: number | string,
	value?: string,
	options?: {
		toFixedAll?: number;
		toFixedValue?: number;
		cryptoCurrency?: string[];
	},
) => string;

export const roundingNumber: IRoundingNumber = (number, value, options) => {
	const fiatZero = 2;
	const cryptoZero = 5;
	let roundingNumberFilter = '';
	const currentValue = value || '';
	const toFixedAll = options?.toFixedAll || fiatZero;
	const toFixedValue = options?.toFixedValue || cryptoZero;
	const cryptoCurrency = options?.cryptoCurrency || [
		'BTC',
		'LTC',
		'BCH',
		'ETH',
		'TRX',
		'XRP',
		'crypto',
	];
	let valueNum = 0;
	cryptoCurrency.forEach((e) => {
		if (e.toLowerCase() === currentValue.toLowerCase()) {
			roundingNumberFilter = Number(number).toFixed(toFixedValue);
			valueNum = toFixedValue;
		}
	});

	if (roundingNumberFilter === '') {
		roundingNumberFilter = Number(number).toFixed(toFixedAll);
		valueNum = toFixedAll;
	}

	const distanceNumber = roundingNumberFilter
		.replace(/(\d+(\.\d+[1-9])?)(\.?0+$)/, '$1')
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	const lengthDelete = distanceNumber.indexOf('.');
	const arrStart = distanceNumber.slice(0, Number(lengthDelete));
	const arrEnd = distanceNumber.slice(Number(lengthDelete)).replace(/\s+/g, '');
	let numberFix = arrStart + arrEnd;
	const numberFixArrayReverse = numberFix.split('').reverse();
	// fiat zero
	if (numberFixArrayReverse[1] === '.' && valueNum === fiatZero) {
		numberFix += '0';
	}
	if (
		numberFixArrayReverse[2] !== '.' &&
		numberFixArrayReverse[1] !== '.' &&
		valueNum === fiatZero
	) {
		numberFix += '.00';
	}
	// crypto zero

	if (numberFixArrayReverse[4] === '.' && valueNum === cryptoZero) {
		numberFix += '0';
	}
	if (
		numberFixArrayReverse[4] !== '.' &&
		numberFixArrayReverse[3] === '.' &&
		valueNum === cryptoZero
	) {
		numberFix += '00';
	}
	if (
		numberFixArrayReverse[4] !== '.' &&
		numberFixArrayReverse[3] !== '.' &&
		numberFixArrayReverse[2] === '.' &&
		valueNum === cryptoZero
	) {
		numberFix += '000';
	}
	if (
		numberFixArrayReverse[4] !== '.' &&
		numberFixArrayReverse[3] !== '.' &&
		numberFixArrayReverse[2] !== '.' &&
		numberFixArrayReverse[1] === '.' &&
		valueNum === cryptoZero
	) {
		numberFix += '0000';
	}
	if (
		numberFixArrayReverse[5] !== '.' &&
		numberFixArrayReverse[4] !== '.' &&
		numberFixArrayReverse[3] !== '.' &&
		numberFixArrayReverse[2] !== '.' &&
		numberFixArrayReverse[1] !== '.' &&
		numberFixArrayReverse[0] !== '.' &&
		valueNum === cryptoZero
	) {
		numberFix += '.00000';
	}
	if (numberFix.toString() === 'NaN' || numberFix.toString() === 'undefined') {
		numberFix = '-';
	}
	return numberFix.replace(/ /g, ',');
};
