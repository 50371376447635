export type TTermsContent = {
	id?: number;
	title: string;
	content: string | null;
};

const TermsContent: TTermsContent[] = [
	{
		id: 1,
		title: '1. ELIGIBILITY',
		content: `By accessing or using the Site, you represent and warrant that you are at least 18 years old and have not previously been suspended or removed from the Site. You also represent and warrant that you are not a resident of the State of New York. You represent and warrant that you are not: (a) located in, under the control of, or a national or resident of any country to which the United States has embargoed goods or services, (b) identified as a "Specially Designated National", or (c) placed on the Commerce Department's Denied Persons List. You further represent and warrant that you will not use the Site if the laws of your country prohibit you from doing so in accordance with these Terms. Finally, you represent and warrant that you will not be using this site for any illegal activity, including but not limited to money laundering and the financing of terrorism.`,
	},
	{
		id: 2,
		title: '2. REGISTRATION AND ACCOUNT',
		content: `In order to access and use the Services, you must create an account with Kaiserex (an “Account”). You agree to: (a) provide accurate, current and complete information when creating the account; (b) maintain and promptly update your Account information to keep it accurate, complete, and current; (c) maintain the security and confidentiality of your login credentials and restrict access to your Account and your computer; (d) promptly notify Kaiserex if you discover or otherwise suspect any security breaches related to the Site; and (e) take responsibility for all activities that occur under your Account and accept all risks of unauthorized access.`,
	},
	{
		id: 3,
		title: '3. PRIVACY POLICY',
		content:
			'Your privacy is important to us. We only share your information with third parties as outlined in our Privacy Policy or required to do so by law enforcement, court order, or in compliance with legal reporting obligations.',
	},
	{
		id: 4,
		title: '4. CONFIDENTIALITY OF THE TRANSMISSION OF INFORMATION OVER THE INTERNET',
		content: `The transmission of data or information (including communications by e-mail) over the Internet or other publicly accessible networks is not one hundred percent secure, and is subject to possible loss, interception, or alteration while in transit. Accordingly, Kaiserex does not assume any liability, without limitation, for any damage you may experience or costs you may incur as a result of any transmissions over the Internet or other publicly accessible networks, including but not limited to transmissions involving the Platform or e-mail with Kaiserex containing your personal information. While Kaiserex will take commercially reasonable efforts to safeguard the privacy of the information you provide to Kaiserex and will treat such information in accordance with Kaiserex's Privacy Policy, in no event will the information you provide to Kaiserex be deemed to be confidential, create any fiduciary obligations for Kaiserex, or result in any liability for Kaiserex in the event that such information is negligently released by Kaiserex or accessed by third parties without Kaiserex's consent.`,
	},
	{
		id: 5,
		title: '5. KAISEREX DOES NOT TAKE ANY LIABILYTI FOR GIVEN INVESTING ADVICE',
		content: `We are an execution-only service coupled with educational information written by Kaiserex’s researchers. In entering into any transaction on the Platform, you represent that you have been, are, and will be solely responsible for making your own independent appraisal and investigations into the risks of the transaction. You represent that you have sufficient knowledge, market sophistication, professional advice and experience to make your own evaluation of the merits and risks of any transaction. We give you no warranty as to the suitability of the CRYPTO5 traded under these Terms and assume no fiduciary duty in our relations with you.

			If at any point Kaiserex or its representatives provide trading recommendations, market commentary, or any other information, the act of doing so is incidental to your relationship with us and imposes no obligation of truth or due diligence on behalf of Kaiserex or its representatives. We give no representation, warranty, or guarantee as to the accuracy or completeness of any such information or as to the tax consequences of any transaction.`,
	},
	{
		id: 6,
		title: '6. LIMITED LICENSE',
		content: `Kaiserex grants you a limited, nonexclusive, nontransferable license, subject to these Terms, to access and use the Services, and the content, materials, information and functionality available in connection therewith (collectively, the “Content”) solely for information, transactional, or other approved purposes as permitted by Kaiserex from time to time. Any other use of the Services or Content is expressly prohibited. All other rights in the Platform or Content are reserved by us and our licensors. You will not otherwise copy, transmit, distribute, sell, resell, license, de-compile, reverse engineer, disassemble, modify, publish, participate in the transfer or sale of, create derivative works from, perform, display, incorporate into another website, or in any other way exploit any of the Content or any other part of the Services or any derivative works thereof, in whole or in part for commercial or non-commercial purposes. Without limiting the foregoing, you will not frame or display the Site or Content (or any portion thereof) as part of any other web site or any other work of authorship without the prior written permission of Kaiserex. If you violate any portion of these Terms, your permission to access and use the Platform may be terminated pursuant to these Terms. In addition, we reserve the right to all remedies available at law and in equity for any such violation. “Kaiserex.com”, “Kaiserex”, and all logos related to the Services or displayed on the Site are either trademarks or registered marks of Kaiserex or its licensor. You may not copy, imitate or use them without Kaiserex’s prior written consent.`,
	},
	{
		id: 7,
		title: '7. COPYRIGHT INFRINGEMENT',
		content: `If you believe anything on the Site infringes upon any copyright which you own or control, you may file a notification of such infringement with our Designated Agent as set forth below.

			UAB Kaiser Exchange International
			Bitininku g. 6B-31, 08417, Vilnius
			Lithuania
			Attn: Copyright Agent
			
			You should note that if you knowingly misrepresent in your notification that the material or activity is infringing, you will be liable for any damages, including costs and attorneys' fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.`,
	},
	{
		id: 8,
		title: '8. 51% AND OTHER CRYPTOCURRENCY ATTACKS',
		content:
			'Kaiserex makes every commercially reasonable attempt to help prevent and mitigate cryptocurrency attacks. If Kaiserex is able to confirm that a cryptocurrency active on the Platform has been compromised or is under attack, Kaiserex may immediately halt trading, deposits, and withdrawals for such cryptocurrency. If it is determined that such an attack caused the cryptocurrency to greatly decrease in value, Kaiserex may discontinue trade activity on such cryptocurrency entirely. Resolutions concerning deposits, withdrawals, and user balances for an attacked cryptocurrency will be determined on a case-by-case basis. Kaiserex makes no representation and does not warrant the safety of the Platform and is not liable for any lost value or stolen property, whether or not Kaiserex was negligent in providing the proper security.',
	},
	{
		id: 9,
		title: '9. (AML) Anti-Money Laundering & (KYC) Know Your Customer Policy',
		content: `Kaiserex protects itself from involvement in any activity that facilitates money laundering or other criminal activities as outlined in our AML & KYC Policy.`,
	},
	{
		id: 10,
		title: '10. THIRD PARTY CONTENT',
		content:
			'Kaiserex and its users may provide third party content on the Site and may provide links to web pages and content that are not owned or controlled by Kaiserex (collectively the "Third Party Content") as a service to those interested in this information. Kaiserex does not control, endorse, or adopt any Third Party Content and makes no representation or warranties of any kind regarding the Third Party Content, including but not limited to its accuracy or completeness. You acknowledge and agree that Kaiserex is not responsible or liable in any manner for any Third Party Content and undertakes no responsibility to update or review any Third Party Content. You acknowledge that your use of such Third Party Content is at your own risk. Your business dealings or correspondence with, or participation in promotions of, any third parties, and any terms, conditions, warranties, or representations associated with such dealings or promotions, are solely between you and such third parties. Kaiserex is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or promotions or as the result of the presence of such Third Party Content on the Site.',
	},
	{
		id: 11,
		title: '11. COPYRIGHT OF FEEDBACK MATERIALS',
		content:
			'You acknowledge and agree that any materials, including but not limited to questions, comments, feedback, suggestions, ideas, plans, notes, drawings, original or creative materials or other information, regarding Kaiserex or the Services (collectively, "Feedback") that are provided by you, whether by email, posting to the Site or otherwise, are non-confidential and will become the sole property of Kaiserex. Kaiserex will own exclusive rights, including all intellectual property rights, and will be entitled to the unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you.',
	},
	{
		id: 12,
		title: '12. USER CONDUCT AND OBLIGATIONS',
		content: `In connection with your use of the Services, you will not:

		Violate or assist any party in violating any law, statute, ordinance, regulation or any rule of any self-regulatory or similar organization of which you are or are required to be a member through your use of the Services;
		Provide false, inaccurate or misleading information;
		Infringe upon Kaiserex’s or any third party’s copyright, patent, trademark, or intellectual property rights;
		Distribute unsolicited or unauthorized advertising or promotional material, any junk mail, spam, or chain letters;
		Reverse engineer or disassemble any aspect of the Site or Services in an effort to access any source code, underlying ideas and concepts, and algorithms;
		Take any action that imposes an unreasonable or disproportionately large load on our infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data, or information;
		Transmit or upload any material to the Site that contains viruses, Trojan horses, worms, or any other harmful or deleterious programs;
		Otherwise attempt to gain unauthorized access to the Site, other Kaiserex Accounts, computer systems or networks connected to the Site, through password mining or any other means; or
		Transfer any rights granted to you under these Terms.`,
	},
	{
		id: 13,
		title: '13. TRANSFERABILITY',
		content: `While the Account and the Services provided to a user are not transferable under any circumstance and shall be used only by the user, Kaiserex shall have the right to transfer, assign, or sell all the rights, benefits, or obligations to any person and these Terms shall continue to be in force and effect for the benefit of the successors and assigns of Kaiserex.`,
	},
	{
		id: 14,
		title: '14. ELECTRONIC TRADING TERMS',
		content: `A transaction on the Platform may fail for several reasons, including but not limited to change in seller prices or unanticipated technical difficulties. We make no representation or warrant that any transaction will be executed properly. Kaiserex is under no circumstances liable for any loss or injury suffered by a failure of a transaction to complete properly. The User has full responsibility to determine and inquire into the failure of any transaction the User initiates.

		Kaiserex processes virtual currency buy and sell orders manually with various individuals and on different markets. This is a unique service provided by the company. Individual virtual currencies makes CRYPTO5 indice which is bought or sold by the client.
		
		To ensure transparency Kaiserex will send a detailed acquisition receipt. You have the right to request proof of virtual currency acquisition such as screenshots of market orders, chat histories, etc. Proof request costs 10EUR and will be charged to your account.
		
		Kaiserex tries to acquire CRYPTO5 currencies at the lowest price possible to the best of its abilities. Company will not take any responsibility for client losses due to market volatility or unsatisfactory virtual currency acquisition prices.
		
		We shall be entitled to act for you upon instructions given by or purporting to be given by you or any person authorized on your behalf without further inquiry as to the genuineness, authority, or identity of the person giving or purporting to give such instructions, provided such instruction is accompanied by correct information about your Account.
		
		You agree that for the safety of your funds virtual currency withdrawals will be delayed for 48 hours from the time of withdrawal request submission. During the 48 hour delay, Kaiserex will send a notification about an ongoing withdrawal to your phone or email. You will have the right to cancel an ongoing virtual currency withdrawal by contacting us via email. Kaiserex will not take any responsibility for client losses due to market volatility or unsatisfactory virtual currency prices resulting from delayed withdrawal.
		
		Kaiserex reserves the right to refuse to process, or the right to cancel or reverse, any transaction on the Platform where Kaiserex suspects the transaction involves money laundering, terrorist financing, fraud, or any other type of crime or if Kaiserex suspects the transaction related to Prohibited Use as stated in our Terms of Use.`,
	},
	{
		id: 15,
		title: '15. PERMANENT WITHDRAWAL OF SERVICE',
		content: `Kaiserex may (a) suspend or terminate your access to the Services, and (b) deactivate or cancel your Account as required by a valid subpoena or court order, or if Kaiserex reasonably suspects you of using your Account in furtherance of illegal activity. You will be permitted to transfer cryptocurrency associated with your Account for ninety (90) days after Account deactivation or cancellation unless such transfer is otherwise prohibited (i) under the law, or (ii) by a valid subpoena or court order. If any transaction is in a pending state at the time your Account is cancelled or suspended, such transaction may be cancelled and/or refunded as appropriate. You may not cancel your Account to evade an investigation or avoid paying any amounts otherwise due to Kaiserex. Upon cancellation of your Account, you authorize Kaiserex to cancel or suspend pending transactions and, after providing written notice to the you, return the funds associated with such transactions to your wallet address. In the event that you or Kaiserex terminates this agreement or your access to the Services, or deactivates or cancels your Account, you will remain liable for all amounts due hereunder. In the event that a technical problem causes system outage or account errors, Kaiserex may temporarily suspend access to your Account until the problem is resolved.`,
	},
	{
		id: 16,
		title: '16. RIGHT TO REFUSE SERVICE',
		content: `Due to rising demand for CRYPTO5 purchases Kaiserex may refuse service to its new or current clients. Existing client would receive 30 days prior notice. Such client can still store their EUR, CRYPTO5 or other cryptocurrencies on Kaiserex's platform as long as they wish and sell them or withdraw without any fees. The only limitation would be the suspension of placing new buy CRYPTO5 orders.`,
	},
	{
		id: 17,
		title: `17. CONFIDENTIALITY OF EMERGENCY NOTIFICATIONS`,
		content: `During critical market conditions we may send our clients emergency notifications, which includes our opinion and decisions how we protect our own investments in current situation. This notification will enable clients to copy our actions by replying YES to the email. All content in the email is to be considered confidential and may not be shared with any third party. Sharing such information may result in financial losses for our clients and Kaiserex. Breaking confidentiality agreement will result in suspension of your account. After suspension you will be able only to withdraw funds and will not receive any future notifications. After funds have been withdawn the account will be closed permanently.`,
	},
	{
		id: 18,
		title: '18. OWNERSHIP OF FUNDS',
		content: `You hereby certify to us that any funds used by you in connection with the Platform are either owned by you or that you are validly authorized to carry out transactions using such funds, and that all transactions initiated with your Account are for your own account and not on behalf of any other person or entity.
		`,
	},
	{
		id: 19,
		title: '19. INDEMNIFICATION',
		content: `You agree to indemnify, defend and hold Kaiserex, its affiliates and service providers, and each of their respective officers, directors, agents, joint venturers, employees, and representatives, harmless from any claim or demand (including attorneys’ fees and costs and any fines, fees or penalties imposed by any regulatory authority) arising out of or related to (i) your breach of these Terms, (ii) your use of Services, or (iii) your violation of any law, rule, or regulation, or the rights of any third party.`,
	},
	{
		id: 20,
		title: '20. DISCLAIMER OF WARRANTEES',
		content: `KAISEREX PROVIDES NO GUARANTEE AS TO THE PERFORMANCE OR THE UNINTERRUPTED AVAILABILITY OF THE SERVICES. THE SERVICES ARE PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. KAISEREX DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT WITH RESPECT TO THE SERVICES. KAISEREX DOES NOT REPRESENT OR WARRANT THAT THE SERVICES AND THE INFORMATION CONTAINED THEREIN ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. KAISEREX WILL MAKE REASONABLE EFFORTS TO ENSURE THAT TRANSACTIONS ON THE PLATFORM ARE PROCESSED IN A TIMELY FASHION, BUT MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESEPCT TO THE AMOUNT OF TIME NEEDED TO PROCESS SUCH TRANSACTIONS. BECAUSE CRYPTOCURRENCY TRANSFERS ON AND OFF THE PLATFORM ARE DEPENDENT UPON MANY FACTORS OUTSIDE OF OUR CONTROL, KAISEREX MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THE SUCCESS OF, OR THE AMOUNT OF TIME NEEDED FOR, CRYPTOCURRENCY TRANSACTIONS.

		SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED TERMS IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE DISCLAIMERS IN THIS SECTION MAY NOT APPLY TO YOU.`,
	},
	{
		id: 21,
		title: '21. LIMITATION OF LIABILITY',
		content: `TO THE MAXIMUM EXTENT PERMITTED BY LAW, KAISEREX SHALL HAVE NO LIABILITY FOR ANY DAMAGES OF ANY KIND (INCLUDING WITHOUT LIMITATION INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR TORT DAMAGES, OR LOST PROFITS) IN CONNECTION WITH YOUR USE OF THE SERVICES, EVEN IF KAISEREX HAS BEEN ADVISED OR IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL KAISEREX’S LIABILITY FOR MONEY DAMAGES UNDER THESE TERMS EXCEED THE AMOUNT OF FEES RECEIVED FROM YOU DURING THE PRECEDING SIX (6) MONTH PERIOD.`,
	},
	{
		id: 22,
		title: '22. SURVIVAL',
		content: `Sections 10 (Third Party Content), 11 (Feedback), 17 (Indemnification), 18 (Disclaimer of Warranties), 19 (Limitation on Liability), 20 (Survival), 21 (Severability), and 23 (Force Majeure) will survive any termination or expiration of these Terms.
		`,
	},
	{
		id: 23,
		title: '23. SEVERABILITY',
		content: `If any provision of these Terms is deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provisions.`,
	},
	{
		id: 24,
		title: '24. INTEGRATION',
		content: `The failure of us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. These Terms and any policies or operating rules posted by us constitutes the entire agreement and understanding between you and us and govern your use of the Services, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of these Terms). Any ambiguities in the interpretation of these Terms shall not be construed against the drafting party.`,
	},
	{
		id: 25,
		title: '25. FORCE MAJEURE',
		content: `In addition to applicable disclaimers stated above, Kaiserex's performance under these Terms shall be excused in the event of interruption and/or delay due to, or resulting from, causes beyond its reasonable control, including but not limited to acts of God, acts of any government, war or other hostility, civil disorder, the elements, fire, flood, earthquake, explosion, embargo, acts of terrorism, power failure, equipment failure, industrial or labor disputes or controversies, acts of any third party data provider(s) or other third party information provider(s), third party software, or communication method interruptions.`,
	},
	{
		id: 26,
		title: '26. GOVERNING LANGUAGE AND TRANSLATIONS',
		content: `You agree that these Terms, Kaiserex's Privacy Policy and other notices posted through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereto, the English language version of such documents shall govern.`,
	},
	{
		id: 27,
		title: '27. QUESTIONS AND CONTACT INFORMATION',
		content: `To contact support, go to Kaiserex and click on Contact us. Please provide all relevant information, including your Kaiserex username and transaction IDs of any related deposits. Although we make no representations or provide no warranties about the speed of response, we will get back to you as soon as possible, typically within 24 hours.`,
	},
];

export default TermsContent;
