import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const ErrorPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<div>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
				}}
			>
				<div className="popup">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
					<button className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title popup-header__title red">{title || 'Error'}</p>
					</div>
					<div className="popup-icon popup-icon--error">
						<svg
							width="81"
							height="80"
							viewBox="0 0 81 80"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect x="1" y="0.5" width="79" height="79" rx="39.5" stroke="#EB5757" />
							<rect x="21" y="20" width="40" height="40" rx="20" fill="#EB5757" />
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M40.9998 30.7C35.8636 30.7 31.6998 34.8638 31.6998 40C31.6998 45.1363 35.8636 49.3 40.9998 49.3C46.1361 49.3 50.2998 45.1363 50.2998 40C50.2998 34.8638 46.1361 30.7 40.9998 30.7ZM30.2998 40C30.2998 34.0906 35.0904 29.3 40.9998 29.3C46.9093 29.3 51.6998 34.0906 51.6998 40C51.6998 45.9095 46.9093 50.7001 40.9998 50.7001C35.0904 50.7001 30.2998 45.9095 30.2998 40ZM41.0002 33.2001C41.442 33.2001 41.8002 33.5582 41.8002 34.0001V43.0001C41.8002 43.4419 41.442 43.8001 41.0002 43.8001C40.5584 43.8001 40.2002 43.4419 40.2002 43.0001V34.0001C40.2002 33.5582 40.5584 33.2001 41.0002 33.2001ZM41.8002 46.0001C41.8002 45.5582 41.442 45.2001 41.0002 45.2001C40.5584 45.2001 40.2002 45.5582 40.2002 46.0001V46.5001C40.2002 46.9419 40.5584 47.3001 41.0002 47.3001C41.442 47.3001 41.8002 46.9419 41.8002 46.5001V46.0001Z"
								fill="white"
							/>
						</svg>
					</div>
					<div className="popup-body">
						{!!message && (
							<div className="popup-text popup-text--mt-24 popup-text--mb-8">
								<p>{message}</p>
							</div>
						)}
					</div>
					<div className="popup-footer">
						<div className="popup-submit">
							<button
								onClick={() => {
									closeModal();
								}}
								type="button"
								className="btn btn-primary btn--warning"
							>
								Ok
							</button>
						</div>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default ErrorPopUp;
