import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	twoCodeRequest,
	twoCodeUpdateRequest,
	twoFoInitState,
	twoRequest,
	twoSuccess,
} from './reducer';
import { ITwoFaRequest, ITwoFaResponse, ITwoFaUpdateRequest } from './types';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { notificationsMessagesError } from '../../../services/utils/notificationsMessages/notificationsMessagesError';
import { popUpOpen } from '../popUp/reducer';
import { clearError } from '../errors/reducer';
import { enableTwoFaSuccess } from '../auth/reducer';

// =============================================================:

// =============================================================:
function* twoRequestWorker() {
	try {
		yield put(showLoading());
		const response: ITwoFaResponse = yield call(api.twoFa.twoFa);
		yield put(twoSuccess(response));
	} catch (error) {
		// notificationContainer('Please enter your 2FA code', 'error');
		yield put(twoFoInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* twoRequestCodeWorker(action: PayloadAction<ITwoFaRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.twoFa.twoFaEnable, payload);
		payload.history.push('/security-settings');
		// notificationContainer(notificationsMessagesInfo.twoFaSuccess, 'success');
		yield put(popUpOpen('enabled2FAPopUp'));
		yield put(enableTwoFaSuccess());
	} catch (error) {
		// console.log(error);
	} finally {
		yield put(hideLoading());
	}
}
function* twoFaUpdateWorker({ payload }: PayloadAction<ITwoFaUpdateRequest>) {
	try {
		yield put(clearError());
		yield call(api.twoFa.twoFaUpdate, payload);
		yield put(popUpOpen('updateTwoFaSuccess'));
	} catch (error) {
		// console.log(error);
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* twoFaSaga() {
	yield takeEvery(twoRequest.type, twoRequestWorker);
	yield takeEvery(twoCodeRequest.type, twoRequestCodeWorker);
	yield takeEvery(twoCodeUpdateRequest, twoFaUpdateWorker);
}
