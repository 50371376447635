/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ICompanyStore,
	TradingActivitiesEnum,
	TradingFrequencyEnum,
	AverageTransactionSizeEnum,
	CompanyStepsEnum,
	ICorporateInformation,
	ITradingActivities,
	IFunds,
	IWealthBusinessIncome,
	IWealthEmploymentIncome,
	IWealthSavings,
	IWealthGiftOrInheritance,
	IWealthExternalFunding,
	IWealthProfitFromInvestments,
	IWealthProfitFromPropertySale,
	IWealthOther,
} from './types';

// ==========================================:
export const initialState: ICompanyStore = {
	company_data: {
		token: 'default',
		company_name: 'default',
		registration_number: 'default',
		registration_country: 'default',
		website: 'default',
		trading_activities: TradingActivitiesEnum.DEFAULT,
		trading_frequency: TradingFrequencyEnum.DEFAULT,
		average_transaction_size: AverageTransactionSizeEnum.DEFAULT,
		business_income: 0,
		business_name: 'default',
		business_amount: 1,
		business_date: '1997-04-18',
		employment_income: 0,
		employment_income_employer: 'default',
		employment_income_occupation: 'default',
		employment_income_gross_annual_salary: 1,
		savings: 0,
		savings_balance: 1,
		savings_currency: 'default',
		savings_date: '1997-04-18',
		gift_or_inheritance: 0,
		gift_or_inheritance_benefactor: 'default',
		gift_or_inheritance_amount: 1,
		gift_or_inheritance_date: '1997-04-18',
		external_funding: 0,
		external_funding_investor: 'default',
		external_funding_amount: 1,
		external_funding_date: '1997-04-18',
		profit_from_investments: 0,
		profit_from_investments_investment_type: 'default',
		profit_from_investments_investment_amount: 1,
		profit_from_investments_investment_date: '1997-04-18',
		profit_from_property_sale: 0,
		profit_from_property_sale_location: 'default',
		profit_from_property_sale_amount: 1,
		profit_from_property_sale_date: '1997-04-18',
		other: 0,
		other_details: 'default',
		bank_name: 'default',
		swift_bic: 'default',
		bank_address: 'default',
		beneficiary_name: 'default',
		account_number: 'default',
		beneficiary_address: 'default',
		bank_currency: 'default',
		other_bank_currency: 'default',
		cryptocurrency_wallet_address: 'default',
		cryptocurrency: 'default',
	},
	active_step: CompanyStepsEnum.CORPORATE_INFORMATION,
	loading: false,
};

// ==========================================:2
const company = createSlice({
	name: '@@company',
	initialState,
	reducers: {
		changeCorporateInformation: (state, action: PayloadAction<ICorporateInformation>) => {
			const { payload } = action;
			const newState = state;

			newState.company_data = {
				...newState.company_data,
				...payload,
			};

			newState.active_step = CompanyStepsEnum.TRADING_ACTIVITIES;
		},
		changeTradingActivities: (state, action: PayloadAction<ITradingActivities>) => {
			const { payload } = action;
			const newState = state;

			newState.company_data = {
				...newState.company_data,
				...payload,
			};

			newState.active_step = CompanyStepsEnum.SOURCE_OF_WEALTH;
		},
		changeSourceOfWealth: (state, action: PayloadAction<{ wealth_type?: string }>) => {
			const { payload } = action;
			const newState = state;

			newState.company_data = {
				...newState.company_data,
				...payload,
			};

			newState.active_step = CompanyStepsEnum.SOURCE_OF_FUNDS;
		},
		changeSourceOfFunds: (state, action: PayloadAction<IFunds>) => {
			const { payload } = action;
			const newState = state;

			newState.company_data = {
				...newState.company_data,
				...payload,
			};
		},
	},
});

export default company.reducer;
export const {
	changeCorporateInformation,
	changeTradingActivities,
	changeSourceOfWealth,
	changeSourceOfFunds,
} = company.actions;
