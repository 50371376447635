import { FC } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import toastifyErrorHeading from '../../../assets/img/icons/toast_error.svg';
import { IToastifyComponent } from '../types';

const UserRejected: FC<IToastifyComponent> = ({ message, toastProps, title }) => {
	return (
		<div className="toastify-component">
			<div className="toastify-component__heading">
				<img src={toastifyErrorHeading} width="30" height="30" alt="" />
				<p>{title}</p>
			</div>
			<div className="toastify-component__content">
				We’re sorry to inform you that your application was rejected. <br /> If you have any further
				questions, please write us at <a href="mailto:support@oneify.io">support@oneify.io</a>.
			</div>
		</div>
	);
};

export default UserRejected;
