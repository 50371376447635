import React from 'react';
import DropFileInput from './drop-file-input/DropFileInput';

const Verification = () => {
	return (
		<div className="verification">
			<DropFileInput />
		</div>
	);
};

export default Verification;
