/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IFiatDepositRequest,
	ITopPairItem,
	ITradeExchangeRatePayload,
	ITradeExchangeRateResponse,
	ITradeQuoteRequestPayload,
	ITradeQuoteResponse,
	ITradeResponse,
	TApiGetCryptoHistoryResponse,
	TApiGetFiatHistoryResponse,
	TApiGetTradeHistoryResponse,
	IFeesByAssetResponse,
	ICryptoWithdrawRequestWithSuccessFunc,
	IFiatWithdrawRequestWithSuccessFunc,
	ILimits,
	IFiatDepositGetFile,
	IFiatDepositGetFileResponse,
} from 'services/api/transactions/types';

import {
	IApiInvoiceData,
	IInvoiceData,
	ITransactionsStore,
	TGetTransactionHistoryRequestPayloadUnsafe,
} from './types';

export const initialState: ITransactionsStore = {
	tradeInfo: {
		from_asset_id: '1',
		to_asset_id: '10',
		price: '0',
		quantity: 1,
		trade_fee_fixed: 0,
		trade_fee_percent: 0,
		withdraw_fee_fixed: 0,
		withdraw_fee_percent: 0,
		deposit_fee_fixed: 0,
		deposit_fee_percent: 0,
		amount: '0',
	},
	tradeInfoModal: {
		price: '0',
	},
	tradeInfoTitle: {
		from_asset_id: '1',
		to_asset_id: '10',
		price: '0',
		quantity: 1,
		trade_fee_fixed: 0,
		trade_fee_percent: 0,
		withdraw_fee_fixed: 0,
		withdraw_fee_percent: 0,
		deposit_fee_fixed: 0,
		deposit_fee_percent: 0,
	},
	fiatHistory: null,
	tradeHistory: null,
	cryptoHistory: null,
	historyLoading: false,
	tradeRequestLoading: false,
	limits: null,
	limitsLoading: false,
	topPair: null,
	invoiceFiles: null,
	invoiceData: null,
	quotesError: true,
};

const transactions = createSlice({
	name: '@@transactions',
	initialState,
	reducers: {
		getCryptoHistoryRequest: (
			state,
			action: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>,
		) => {
			state.historyLoading = true;
		},
		getCryptoHistorySuccess: (state, { payload }: PayloadAction<TApiGetCryptoHistoryResponse>) => {
			state.cryptoHistory = payload;
			state.historyLoading = false;
		},
		getCryptoHistoryError: (state) => {
			state.cryptoHistory = null;
			state.historyLoading = false;
		},

		getFiatHistoryRequest: (
			state,
			action: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>,
		) => {
			state.historyLoading = true;
		},
		getFiatHistorySuccess: (state, { payload }: PayloadAction<TApiGetFiatHistoryResponse>) => {
			state.fiatHistory = payload;
			state.historyLoading = false;
		},
		getFiatHistoryError: (state) => {
			state.fiatHistory = null;
			state.historyLoading = false;
		},

		getTradeHistoryRequest: (
			state,
			action: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>,
		) => {
			state.historyLoading = true;
		},
		getTradeHistorySuccess: (state, { payload }: PayloadAction<TApiGetTradeHistoryResponse>) => {
			state.tradeHistory = payload;
			state.historyLoading = false;
		},

		getTradeExchangeRateModal: (state, { payload }: PayloadAction<any>) => {},
		getTradeExchangeRateModalSuccess: (
			state,
			{ payload }: PayloadAction<ITradeExchangeRateResponse>,
		) => {
			state.tradeInfoModal = {
				...state.tradeInfoModal,
				price: payload.rate.price_for_main_page,
			};
		},

		getTradeQuoteRequest: (state, { payload }: PayloadAction<ITradeQuoteRequestPayload>) => {
			state.historyLoading = true;
		},
		getTradeQuoteSuccess: (state, { payload }: PayloadAction<ITradeQuoteResponse>) => {
			state.historyLoading = false;
			state.quotesError = false;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.tradeInfo = {
				...state.tradeInfo,
				from_asset_id: state?.tradeInfo?.from_asset_id || '',
				to_asset_id: state?.tradeInfo?.to_asset_id || '',
				quantity: state?.tradeInfo?.quantity || 1,
				// price: payload.price,
			};
		},
		getTradeQuoteError: (state) => {
			state.historyLoading = false;
			state.tradeInfo = null;
			state.quotesError = true;
		},

		// getTradeExchangeRateRequest: (state, { payload }: PayloadAction<ITradeExchangeRatePayload>) => {
		getTradeExchangeRateRequest: (state, { payload }: PayloadAction<any>) => {
			state.historyLoading = true;
			state.quotesError = true;
			state.tradeInfo = {
				...state.tradeInfo,
				from_asset_id: payload.from_asset_id,
				to_asset_id: payload.to_asset_id,
				quantity: 1,
				price: state?.tradeInfo?.price || '0',
				price_for_main_page: 0,
				pair_code: payload.pair_code,
			};
		},
		getTradeExchangeRateSuccess: (
			state,
			{ payload }: PayloadAction<ITradeExchangeRateResponse>,
		) => {
			state.historyLoading = false;
			state.tradeInfo = {
				...state.tradeInfo,
				from_asset_id: state?.tradeInfo?.from_asset_id || '',
				to_asset_id: state?.tradeInfo?.to_asset_id || '',
				quantity: 1,
				price: payload.rate.price,
				price_for_main_page: payload.rate.price_for_main_page,
				pair_code: payload.rate.pair_code,
			};
		},
		// new title
		getTradeExchangeRateTitleRequest: (
			state,
			{ payload }: PayloadAction<ITradeExchangeRatePayload>,
		) => {
			state.tradeInfoTitle = {
				...state.tradeInfoTitle,
				from_asset_id: payload.from_asset_id,
				to_asset_id: payload.to_asset_id,
				quantity: 1,
				price: state?.tradeInfoTitle?.price || '0',
			};
		},

		getTradeExchangeRateTitleSuccess: (
			state,
			{ payload }: PayloadAction<ITradeExchangeRateResponse>,
		) => {
			// console.log(payload?.rate?.price);
			state.historyLoading = false;
			state.tradeInfoTitle = {
				...state.tradeInfoTitle,
				from_asset_id: state?.tradeInfoTitle?.from_asset_id || '',
				to_asset_id: state?.tradeInfoTitle?.to_asset_id || '',
				quantity: 1,
				price: payload.rate.price,
				price_for_main_page: payload.rate.price_for_main_page,
				pair_code: payload.rate.pair_code,
			};
		},

		getTradeExchangeRateError: (state) => {
			state.historyLoading = false;
			state.tradeInfo = null;
		},
		getMainTradePairPriceError: (state) => {
			state.historyLoading = false;
		},

		makeTradeRequest: (state, { payload }: PayloadAction<ITradeQuoteRequestPayload>) => {
			state.historyLoading = true;
			state.tradeRequestLoading = true;
		},
		makeTradeSuccess: (state, { payload }: PayloadAction<ITradeResponse>) => {
			state.historyLoading = false;
			state.tradeRequestLoading = false;
			state.tradeInfo = {
				...state.tradeInfo,
				from_asset_id: payload.from_asset_id,
				to_asset_id: payload.to_asset_id,
				quantity: payload.quantity,
				price: payload.rate,
				pair_code: payload.pair,
				price_b2c2: payload.price_b2c2,
				amount: payload.amount,
				fee: payload.fee,
			};
		},
		makeTradeError: (state) => {
			state.historyLoading = false;
			state.tradeRequestLoading = false;
			state.tradeInfo = null;
		},

		getTopPairRequest: (state) => {
			state.historyLoading = true;
		},
		getTopPairSuccess: (state, { payload }: PayloadAction<ITopPairItem[]>) => {
			state.historyLoading = false;
			state.topPair = payload;
		},
		getTopPairError: (state) => {
			state.historyLoading = false;
		},
		tradeInfoInitState: (state) => {
			state.tradeInfo = null;
		},
		createFiatWithdrawRequest: (
			state,
			{ payload }: PayloadAction<IFiatWithdrawRequestWithSuccessFunc>,
		) => {
			state.historyLoading = true;
		},
		createCryptoWithdrawRequest: (
			state,
			{ payload }: PayloadAction<ICryptoWithdrawRequestWithSuccessFunc>,
		) => {
			state.historyLoading = true;
		},

		getFeesByAssetRequest: (state, { payload }: PayloadAction<number | string>) => {
			state.historyLoading = true;
		},
		getFeesByAssetSuccess: (state, { payload }: PayloadAction<IFeesByAssetResponse>) => {
			state.historyLoading = false;
			state.tradeInfo = {
				...state.tradeInfo,
				from_asset_id: state?.tradeInfo?.from_asset_id || '',
				to_asset_id: state?.tradeInfo?.to_asset_id || '',
				quantity: state?.tradeInfo?.quantity || 0,
				price: state?.tradeInfo?.price || '',
				trade_fee_fixed: payload.trade_fee_fixed,
				trade_fee_percent: payload.trade_fee_percent,
				withdraw_fee_fixed: payload.withdraw_fee_fixed,
				withdraw_fee_percent: payload.withdraw_fee_percent,
				deposit_fee_fixed: payload.deposit_fee_fixed,
				deposit_fee_percent: payload.deposit_fee_percent,
			};
		},
		getFeesByAssetError: (state) => {
			state.historyLoading = false;
		},
		getUserLimitsRequest: (state, { payload }: PayloadAction<number>) => {
			state.limitsLoading = true;
			state.limits = null;
		},
		getUserLimitsSuccess: (state, { payload }: PayloadAction<ILimits>) => {
			state.limits = payload;
			state.limitsLoading = false;
		},
		getUserLimitsError: (state) => {
			state.limitsLoading = false;
		},
		postFiatDepositInvoiceCreateRequest: (state, action: PayloadAction<IFiatDepositGetFile>) => {
			state.limitsLoading = false;
		},
		postFiatDepositInvoiceCreateSuccess: (
			state,
			{ payload }: PayloadAction<IFiatDepositGetFileResponse>,
		) => {
			state.invoiceFiles = payload;
			state.limitsLoading = false;
		},
		getInvoiceData: (state, { payload }: PayloadAction<IApiInvoiceData>) => {
			state.invoiceData = payload.invoice;
		},
		clearInvoiceData: (state) => {
			state.invoiceData = null;
		},
	},
});

export default transactions.reducer;
export const {
	getCryptoHistoryRequest,
	getCryptoHistorySuccess,
	getCryptoHistoryError,
	getFiatHistorySuccess,
	getFiatHistoryRequest,
	getFiatHistoryError,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTradeExchangeRateModal,
	getTradeExchangeRateModalSuccess,
	getTradeQuoteRequest,
	getTradeQuoteSuccess,
	getTradeQuoteError,
	getTradeExchangeRateRequest,
	getTradeExchangeRateSuccess,
	getTradeExchangeRateError,
	makeTradeRequest,
	makeTradeSuccess,
	makeTradeError,
	tradeInfoInitState,
	getTopPairRequest,
	getTopPairSuccess,
	getTopPairError,
	createCryptoWithdrawRequest,
	createFiatWithdrawRequest,
	getFeesByAssetRequest,
	getFeesByAssetSuccess,
	getFeesByAssetError,
	getUserLimitsRequest,
	getUserLimitsSuccess,
	getUserLimitsError,
	postFiatDepositInvoiceCreateRequest,
	postFiatDepositInvoiceCreateSuccess,
	getTradeExchangeRateTitleRequest,
	getTradeExchangeRateTitleSuccess,
	getInvoiceData,
	clearInvoiceData,
} = transactions.actions;
