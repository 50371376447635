import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import {
	getWalletAddressesRequest,
	updateWalletAddressRequest,
	deleteWalletAddressRequest,
	createWalletAddressRequest,
	getWalletAddressesSuccess,
	changeWalletAddressesPage,
} from './reducer';
import {
	IWalletAddressId,
	IWalletAddressesPageInfo,
	IWalletAddressGetResponse,
	IWalletAddressRequestPayload,
	IWalletAddressRequestPayloadWithId,
} from './types';
import { getWalletAddressesPageInfo } from './selectors';
import { notificationsMessagesError } from '../../../services/utils/notificationsMessages/notificationsMessagesError';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';
// =============================================================:

// =============================================================:
function* getWalletAddressesRequestWorker() {
	try {
		yield put(showLoading());
		const getPageInfo: IWalletAddressesPageInfo = yield select(getWalletAddressesPageInfo);
		const response: IWalletAddressGetResponse = yield call(
			api.walletAddresses.getWalletAddresses,
			getPageInfo,
		);

		yield put(getWalletAddressesSuccess(response));
	} catch (error) {
		// console.log(error);
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* deleteWalletAddressesRequestWorker(action: PayloadAction<IWalletAddressId>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.walletAddresses.deleteWalletAddress, payload.id);
		if (payload.page) {
			yield put(
				changeWalletAddressesPage({
					per_page: 5,
					current_page: payload.page,
				}),
			);
		}
		yield put(getWalletAddressesRequest());
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletDeleted }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* createWalletAddressesRequestWorker(action: PayloadAction<IWalletAddressRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.walletAddresses.createWalletAddress, payload);
		yield put(getWalletAddressesRequest());
		yield put(getWalletsRequest());
		yield put(popUpOpen('successPopUp'));
		yield put(
			setPopUpData({
				message: notificationsMessagesPopUp.walletSuccessfullyCreated(payload.nickname),
				title: 'Wallet Address Added',
			}),
		);
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletSuccessfullyNotCreated }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* updateWalletAddressesRequestWorker(
	action: PayloadAction<IWalletAddressRequestPayloadWithId>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.walletAddresses.updateWalletAddress, payload);
		yield put(getWalletAddressesRequest());
		yield put(
			setPopUpData({
				message: notificationsMessagesPopUp.walletSuccessfully(payload.nickname),
				title: 'Wallet Address updated',
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletUpdated }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* walletAddressesSaga() {
	yield takeEvery(getWalletAddressesRequest.type, getWalletAddressesRequestWorker);
	yield takeEvery(updateWalletAddressRequest.type, updateWalletAddressesRequestWorker);
	yield takeEvery(deleteWalletAddressRequest.type, deleteWalletAddressesRequestWorker);
	yield takeEvery(createWalletAddressRequest.type, createWalletAddressesRequestWorker);
	yield takeEvery(changeWalletAddressesPage.type, getWalletAddressesRequestWorker);
}
