import React, { FC, MouseEvent, useEffect } from 'react';
import {
	ISortedButton,
	IWalletsTableHeader,
	IWalletsTableHeaderButton,
} from 'components/Wallets/WalletsTable/type';
import { EWalletType } from 'redux/reducers/wallets/types';

const SortedButton: FC<ISortedButton> = ({
	title,
	sortType,
	sortOrderType,
	setSortType,
	sortMagnitude,
	setSortMagnitude,
}) => {
	useEffect(() => {
		if (sortType) {
			setSortMagnitude('desc');
		}
	}, [sortType, setSortMagnitude]);

	const handleSorted = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setSortType(name);

		if (!sortMagnitude) setSortMagnitude('asc');

		if (sortMagnitude === 'asc') setSortMagnitude('desc');

		if (sortMagnitude === 'desc') setSortMagnitude('');
	};

	return (
		<div className="td">
			<button
				type="button"
				className="td-sorting-arrow-button"
				name={sortOrderType}
				onClick={handleSorted}
			>
				{String(title)}{' '}
				<div className="td-sorting-arrow">
					<span
						className={`td-sorting-arrow__item ${
							sortMagnitude === 'desc' && sortType === sortOrderType ? 'active' : ''
						}`}
					>
						<svg
							width="6"
							height="6"
							viewBox="0 0 8 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3.52021 0.902237C3.76021 0.617793 4.24021 0.617793 4.48021 0.902237L7.78021 4.81335C8.07687 5.16494 7.79464 5.66668 7.30021 5.66668H0.700211C0.205783 5.66668 -0.0764459 5.16494 0.22021 4.81335L3.52021 0.902237Z"
								fill="#626A85"
							/>
						</svg>
					</span>
					<span
						className={`td-sorting-arrow__item ${
							sortMagnitude === 'asc' && sortType === sortOrderType ? 'active' : ''
						}`}
					>
						<svg
							width="6"
							height="6"
							viewBox="0 0 8 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.48021 5.86C4.24021 6.18 3.76021 6.18 3.52021 5.86L0.22021 1.46C-0.0764459 1.06446 0.205783 0.5 0.70021 0.5L7.30021 0.5C7.79464 0.5 8.07687 1.06446 7.78021 1.46L4.48021 5.86Z"
								fill="#626A85"
							/>
						</svg>
					</span>
				</div>
			</button>{' '}
		</div>
	);
};

const WalletsTableHeader: FC<IWalletsTableHeader> = ({
	sortMagnitude,
	setSortMagnitude,
	sortType,
	setSortType,
	type,
}) => {
	const TableHeaderButtons: IWalletsTableHeaderButton[] = [
		{
			title: type === EWalletType.FIAT ? 'Currency' : 'Cryptocurrency',
			sortOrderType: 'code',
		},
		{
			title: 'Balance',
			sortOrderType: 'total',
		},
		{
			title: 'Available',
			sortOrderType: 'balance',
		},
		{
			title: 'Pending',
			sortOrderType: 'frozen_balance',
		},
		{
			title: 'EUR Value',
			sortOrderType: 'balance_eur',
		},
	];
	return (
		<div className="table-header">
			<div className="tr">
				{TableHeaderButtons.map((el) => (
					<SortedButton
						key={el.sortOrderType}
						{...el}
						sortMagnitude={sortMagnitude}
						setSortMagnitude={setSortMagnitude}
						sortType={sortType}
						setSortType={setSortType}
					/>
				))}
				<div className="td td--right" />
			</div>
		</div>
	);
};

export default WalletsTableHeader;
