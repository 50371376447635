/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import PhoneInputField from 'ui/PhoneInputField';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { supportRequest } from 'redux/reducers/download/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { CountryCode, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { IPopUp } from '../types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';

const SupportPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [counterStateCode, setStateCounterCode] = useState('US');

	const [phonePrefix, setPhonePrefix] = useState<string>('+1');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [fullName, setFullName] = useState<string | null>('');

	const onChange = (value: string, counterCode: string) => {
		setPhonePrefix(value);
		setStateCounterCode(counterCode);
	};
	const initialValues = {
		email: userData?.email || '',
		fullName,
		phone: phoneNumber,
		message: '',
	};

	useEffect(() => {
		if (userData?.data) {
			const fullPhone = String(userData?.data?.phone).replace('+', '');
			const phoneNumberValue = parsePhoneNumber(`+${fullPhone}`);
			const country = phoneNumberValue?.country || 'US';
			const countryCallingCode = getCountryCallingCode(country);
			setPhonePrefix(countryCallingCode);
			setStateCounterCode(country);
			setPhoneNumber(fullPhone.replace(countryCallingCode, ''));
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			setFullName(`${userData?.data?.first_name} ${userData?.data?.last_name}`);
		}
	}, [userData?.data]);

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.email.required)
			.email(notificationsInfoFields.email.invalid)
			.max(60, notificationsInfoFields.email.maxLength),
		fullName: yup
			.string()
			.min(1, notificationsInfoFields.fullName.min)
			.max(50, notificationsInfoFields.fullName.max)
			.required(notificationsInfoFields.fullName.required),
		phone: yup
			.string()
			.required(notificationsInfoFields.phone.required)
			.max(20, notificationsInfoFields.phone.max)
			.matches(/^\d{9,20}$/, notificationsInfoFields.phone.matches),
		message: yup
			.string()
			.required(notificationsInfoFields.message.required)
			.min(2, notificationsInfoFields.message.min)
			.max(255, notificationsInfoFields.message.max),
	});
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					// history.push('/');
				}}
			>
				<div className="modal">
					<div className="popup">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header">
							<p className="popup-header__title">Support</p>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={(obj, { resetForm, setSubmitting }) => {
								// const newObject = {
								// 	...obj,
								// 	phone: phonePrefix + obj.phone,
								// };
								const params: any = {
									apiParams: {
										name: obj.fullName,
										email: obj.email,
										phone: obj.phone,
										message: obj.message,
									},
									// onFinally: () => setFirstLoading(false),
								};

								dispatch(supportRequest(params));
								setSubmitting(false);
								closeModal();
								resetForm();
							}}
							enableReinitialize
							validateOnBlur
						>
							{({ isSubmitting, isValid, dirty, setFieldValue }) => (
								<Form className="form">
									<div className="popup-body">
										<div className="popup-text">
											<p>
												Need help? Please submit the form below and our Support Team will be in
												touch.
											</p>
										</div>
										<Field
											title="Email Address"
											type="email"
											placeholder="Email Address"
											name="email"
											required
											component={Input}
										/>
										<Field
											title="Full Name"
											type="text"
											placeholder="Full Name"
											name="fullName"
											required
											component={Input}
										/>
										<p className="input__name">Phone Number</p>
										<PhoneInputField
											counterStateCode={counterStateCode}
											onChange={onChange}
											value={phonePrefix}
											enableSearch
											dropdownClass="phone-dropdown"
											searchClass="searchfield"
										>
											<Field
												type="tel"
												placeholder="Phone Number"
												name="phone"
												required
												component={Input}
												setValue={setFieldValue}
											/>
										</PhoneInputField>
										<Field
											title="Message"
											type="textarea"
											placeholder="How can we help?"
											name="message"
											required
											component={Input}
											className="mb-20"
										/>
									</div>
									<div className="popup-footer">
										<div className="popup-submit mt-0">
											<button type="submit" className="btn btn-primary btn--full">
												Submit
											</button>
										</div>
									</div>
									<div className="popup-text mt-20">
										<p>
											You can also message us on{' '}
											<a
												href="https://wa.link/ls0vvd"
												rel="noreferrer"
												className="link"
												target="_blank"
											>
												WhatsApp
											</a>{' '}
											or{' '}
											<a
												href="https://t.me/oneify"
												rel="noreferrer"
												className="link"
												target="_blank"
											>
												Telegram
											</a>
										</p>
									</div>
									<div className="popup-soc">
										<a href="https://wa.link/ls0vvd">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="42"
												height="42"
												viewBox="0 0 42 42"
												fill="none"
											>
												<circle cx="21" cy="21" r="21" fill="#212C63" />
												<path
													d="M28.0045 13.9062C26.1339 12.0312 23.6429 11 20.9955 11C15.5312 11 11.0848 15.4464 11.0848 20.9107C11.0848 22.6562 11.5402 24.3616 12.4063 25.8661L11 31L16.2545 29.6205C17.7009 30.4107 19.3304 30.8259 20.9911 30.8259H20.9955C26.4554 30.8259 31 26.3795 31 20.9152C31 18.2679 29.875 15.7812 28.0045 13.9062ZM20.9955 29.1563C19.5134 29.1563 18.0625 28.7589 16.7991 28.0089L16.5 27.8304L13.3839 28.6473L14.2143 25.6071L14.0179 25.2946C13.192 23.9821 12.7589 22.4688 12.7589 20.9107C12.7589 16.3705 16.4554 12.6741 21 12.6741C23.2009 12.6741 25.2679 13.5312 26.8214 15.0893C28.375 16.6473 29.3304 18.7143 29.3259 20.9152C29.3259 25.4598 25.5357 29.1563 20.9955 29.1563ZM25.5134 22.9866C25.2679 22.8616 24.0491 22.2634 23.8214 22.183C23.5938 22.0982 23.4286 22.058 23.2634 22.308C23.0982 22.558 22.625 23.1116 22.4777 23.2813C22.3348 23.4464 22.1875 23.4688 21.942 23.3438C20.4866 22.6161 19.5312 22.0446 18.5714 20.3973C18.317 19.9598 18.8259 19.9911 19.2991 19.0446C19.3795 18.8795 19.3393 18.7366 19.2768 18.6116C19.2143 18.4866 18.7188 17.2679 18.5134 16.7723C18.3125 16.2902 18.1071 16.3571 17.9554 16.3482C17.8125 16.3393 17.6473 16.3393 17.4821 16.3393C17.317 16.3393 17.0491 16.4018 16.8214 16.6473C16.5938 16.8973 15.9554 17.4955 15.9554 18.7143C15.9554 19.933 16.8438 21.1116 16.9643 21.2768C17.0893 21.442 18.7098 23.942 21.1964 25.0179C22.7679 25.6964 23.3839 25.7545 24.1696 25.6384C24.6473 25.567 25.6339 25.0402 25.8393 24.4598C26.0446 23.8795 26.0446 23.3839 25.9821 23.2813C25.9241 23.1696 25.7589 23.1071 25.5134 22.9866Z"
													fill="white"
												/>
											</svg>
										</a>
										<a href="https://t.me/oneify">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="42"
												height="42"
												viewBox="0 0 42 42"
												fill="none"
											>
												<circle cx="21" cy="21" r="21" fill="#212C63" />
												<path
													d="M30.943 15.3005L27.9249 27.4234C27.6972 28.279 27.1034 28.4919 26.2596 28.0888L21.6609 25.2026L19.442 27.0203C19.1964 27.2294 18.9911 27.4044 18.5178 27.4044L18.8482 23.4154L27.3713 16.8558C27.7418 16.5744 27.2909 16.4185 26.7953 16.6999L16.2587 22.3506L11.7225 21.1414C10.7359 20.879 10.718 20.301 11.9279 19.8979L29.6706 14.0761C30.4921 13.8137 31.2109 14.232 30.943 15.3005Z"
													fill="white"
												/>
											</svg>
										</a>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SupportPopUp;
