import Auth from 'layouts/Auth';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import TransactionsTrade from 'components/TransactionsTrade/TransactionsTrade';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';

const TransactionsTradesPage = () => {
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />

					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<TransactionsTrade />
					</motion.div>
				</div>
			</section>
		</Auth>
	);
};

export default TransactionsTradesPage;
