/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegistrationsPayloadData, IRegistrationsResponse, IRegistrationsStore } from './types';

// ==========================================:
export const initialState: IRegistrationsStore = {
	registrations: null,
	registrationsLoading: false,
};
// ==========================================:
const registrations = createSlice({
	name: '@@registrations',
	initialState,
	reducers: {
		registrationsRequest: (state, action: PayloadAction<IRegistrationsPayloadData>) => {
			const registrationsState = state;
			registrationsState.registrationsLoading = true;
		},
		registrationsSuccess: (state, action: PayloadAction<IRegistrationsResponse>) => {
			const { payload } = action;
			const registrationsState = state;
			registrationsState.registrations = payload;
			registrationsState.registrationsLoading = true;
		},
		registrationsInitState: () => initialState,
	},
});

export default registrations.reducer;
export const { registrationsInitState, registrationsRequest, registrationsSuccess } =
	registrations.actions;
