import {
	ICreateNewPasswordFormData,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordPayload,
	IForgotPasswordResponse,
	IGenerateSmsResponsePayload,
	IipConfirmPayloadData,
	ILoginPayload,
	IResetTwoFaPayload,
	ILoginResponse,
	IRegistrationPayload,
	IRegistrResponse,
} from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) =>
		http
			.post<ILoginPayload, { data: ILoginResponse }>(endpoint.auth.LOGIN, payload)
			.then((response) => {
				return response.data;
			}),

	registration: (payload) =>
		http
			.post<IRegistrationPayload, { data: IRegistrResponse }>(endpoint.auth.REGISTRATION, payload)
			.then((response) => response.data),

	forgotPassword: (payload) =>
		http
			.post<IForgotPasswordPayload, { data: IForgotPasswordResponse }>(
				endpoint.auth.RESET_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	emailConfirm: (payload) => http.put<IEmailConfirmPayload>(endpoint.auth.EMAIL_CONFIRM, payload),

	emailResetConfirmToken: (payload) =>
		http.post<IEmailResetConfirmTokenPayload>(endpoint.auth.RESET_EMAIL_CONFIRM, {
			token: payload.token,
		}),

	ipConfirm: (payload) => http.put<IipConfirmPayloadData>(endpoint.auth.IP_CONFIRM, payload),

	newPassword: (payload) =>
		http
			.put<ICreateNewPasswordFormData, { data: IRegistrResponse }>(
				endpoint.auth.NEW_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	logout: () => http.post(endpoint.auth.LOGOUT),

	generateSms: (payload) =>
		http
			.post<IGenerateSmsResponsePayload>(endpoint.auth.GENERATE_SMS, payload)
			.then((response) => response.data),

	resetTwoFa: (payload) => http.post(endpoint.auth.RESET_TWOFA, payload),

	refreshToken: () =>
		http.get(endpoint.auth.REFRESH_TOKEN).then((response) => {
			return response.data;
		}),

	resetPassword: (payload) =>
		http.post<IResetTwoFaPayload>(endpoint.auth.RESET_PASSWORD, { ...payload }),

	getUser: () => http.get(endpoint.auth.GET_USER).then(({ data }) => data),

	resetTwoFaCode: (payload) =>
		http.post(endpoint.auth.RESET_TWOFA_TOKEN, payload).then(({ data }) => data),
};
