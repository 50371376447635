export enum ApiUserStatus {
	Unverified = 'unverified',
	Verified = 2,
	Pending = 'pending',
	Rejected = 'rejected',
}

export const statusNames: Record<string, string> = {
	[ApiUserStatus.Unverified]: 'Unverified',
	[ApiUserStatus.Verified]: 'Verified',
	[ApiUserStatus.Pending]: 'Pending',
	[ApiUserStatus.Rejected]: 'Rejected',
};

export const dataURLtoBlob = (dataURL: string) => {
	const [dataType, base64Data] = dataURL.split(',');
	const binaryData = Buffer.from(base64Data, 'base64');
	const byteArray = new Uint8Array(Uint8Array.from(binaryData));
	return new Blob([byteArray], { type: dataType.split(':')[1] });
};
