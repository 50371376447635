import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';

interface IPhoneInputField {
	value: string;
	counterStateCode: string;
	children: React.ReactNode;
	onChange: (inputValue: string, counterCode: string) => void;
	enableSearch?: boolean;
	searchClass?: string;
	dropdownClass?: string;
	isDisabled?: boolean;
}

const PhoneInputField: FC<IPhoneInputField> = ({
	children,
	onChange,
	value,
	counterStateCode,
	enableSearch,
	searchClass,
	dropdownClass,
	isDisabled,
}) => {
	const onPhonePrefixChange = (inputValue: string, data: any) => {
		const counterCode = data?.countryCode || 'us';
		onChange(inputValue, counterCode);
	};
	return (
		<div className="react-tel-input-wrapper">
			{children}
			<PhoneInput
				country={counterStateCode.toLowerCase()}
				value={value}
				onChange={onPhonePrefixChange}
				enableSearch={enableSearch}
				dropdownClass={dropdownClass}
				searchClass={searchClass}
				searchPlaceholder=""
				disableDropdown={!!isDisabled}
			/>
		</div>
	);
};

PhoneInputField.defaultProps = {
	enableSearch: false,
	searchClass: '',
	dropdownClass: '',
	isDisabled: false,
};

export default PhoneInputField;
