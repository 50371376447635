import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IRegistrationsApi } from './types';
// ==========================================:

export const registrations: IRegistrationsApi = {
	registrations: (payload) =>
		http.post(endpoint.register.register, payload).then((response) => {
			return response.data;
		}),
};
