import { authInitState } from 'redux/reducers/auth/reducer';
import { settingsInitState } from 'redux/reducers/settings/reducer';
import { errors, setErrorMsg } from 'redux/reducers/errors/reducer';
import { store } from 'redux/store';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IAxiosError } from './types';
import { notificationsMessagesError } from '../../utils/notificationsMessages/notificationsMessagesError';

// ==========================================:
const onError = (error: IAxiosError) => {
	const status = error.response ? error.response.status : null;
	const errorName = error.response ? error.response.data : null;
	store.dispatch(errors(status));
	const errorText = (key: string) => {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		if (notificationsMessagesError[key] === undefined) {
			// delete _
			// add 000005 = 0.00005
			const srt = key.replace(/_/g, ' ');
			const result = srt.charAt(0).toUpperCase() + srt.slice(1);
			return `${result}.`;

			// const resultArr = result.split('');
			// const indexCheck = result.indexOf('0', 0);
			// const insert = (arr: any[], item: string, index: number) =>
			// 	arr.reduce(function (s, a, i) {
			// 		i === index ? s.push(item, a) : s.push(a);
			// 		return s;
			// 	}, []);
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// return `${insert(resultArr, '.', indexCheck + 1).join('')}.`;
		}
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		return notificationsMessagesError[key];
	};

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < errorName.errors.length; i++) {
		if (errorName.errors[i] === 'invalid_credentials') {
			store.dispatch(setErrorMsg('invalid_credentials'));
		} else if (
			errorName.errors[i] === 'no_account_is_associated_with_this_email' ||
			errorName.errors[i] === 'model_not_found'
		) {
			store.dispatch(setErrorMsg('invalid_email'));
		} else if (
			errorName.errors[i] === 'secret_key_is_too_short_must_be_at_least16_base32_characters' ||
			errorName.errors[i] === 'invalid_totp_code'
		) {
			store.dispatch(setErrorMsg('Invalid Authentication Code.'));
		} else if (errorName.errors[i] === 'unauthenticated') {
			store.dispatch(authInitState());
			store.dispatch(settingsInitState());
		} else if (errorName.errors[i] === 'the_captcha_field_is_required') {
			notificationContainer(errorText(errorName.errors[i]), 'error', 'Captcha');
		} else if (errorName.errors[i] === 'you_have_to_enable_2fa') {
			setTimeout(() => {
				const { origin } = window.location;
				window.location.href = `${origin}/security-settings`;
			}, 200);
			// eslint-disable-next-line no-empty
		} else if (errorName.errors[i].includes('out_of_range_value')) {
			// eslint-disable-next-line no-empty
		} else if (
			errorName.errors[i].includes('token_expired') ||
			errorName.errors[i].includes('the_totp_field_is_required') ||
			errorName.errors[i].includes('the_token_has_been_blacklisted')
			// eslint-disable-next-line no-empty
		) {
		} else if (errorName.errors[i] === 'user_blocked') {
			notificationContainer(
				'Your account has been blocked. If you believe this is an error please contact support.',
				'user_blocked',
				'Account Blocked',
			);
		} else if (errorName.errors[i] === 'already_exist') {
			notificationContainer(
				'2FA reset was already requested and is under review.<br>Please wait for the resolution or contact support for more details',
				'user_blocked',
				'Error',
			);
		} else if (errorName.errors[i] === 'your_request_is_pending') {
			notificationContainer(
				'Password reset was already requested and is under review.<br> Please wait for the resolution or contact support for more details',
				'user_blocked',
				'Error',
			);
		} else if (errorName.errors[i] === 'user_in_the_archive') {
			notificationContainer(
				'Your account has been archived.<br> If you believe this is an error please contact support.',
				'user_blocked',
				'Account Archived',
			);
		} else if (
			errorName.errors[i] === 'token_incorrect' &&
			window.location.href.indexOf('create-new-password') > -1
		) {
			notificationContainer(
				'You have already reset your password.<br> Please contact support if you need further assistance',
				'user_blocked',
				'Error',
			);
		} else if (
			errorName.errors[i] ===
			'deposit_account_not_found_please_refer_to_our_support_team_for_guidance'
		) {
			notificationContainer(
				'Please refer to our support team for guidance.',
				'account_not_found',
				'Deposit account not found',
			);
		} else {
			notificationContainer(errorText(errorName.errors[i]), 'error');
		}
		/* switch (errorName.errors[i]) {
			case 'invalid_credentials':
				store.dispatch(setErrorMsg('invalid_credentials'));
				break;
			case 'no_account_is_associated_with_this_email':
				store.dispatch(setErrorMsg('invalid_email'));
				break;
			case 'secret_key_is_too_short_must_be_at_least16_base32_characters':
				store.dispatch(setErrorMsg('Invalid Authentication Code.'));
				// notificationContainer('Invalid Authentication Code.', 'error');
				break;
			// case 'invalid_totp_code':
			// 	store.dispatch(setErrorMsg('invalid_totp_code'));
			// 	break;
			case 'unauthenticated':
				store.dispatch(authInitState());
				break;
			case 'the_captcha_field_is_required':
				notificationContainer(errorText(errorName.errors[i]), 'error', 'Captcha');
				break;
			case 'you_have_to_enable_2fa':
				setTimeout(() => {
					const { origin } = window.location;
					window.location.href = `${origin}/security-settings`;
				}, 200);
				break;
			default:
				notificationContainer(errorText(errorName.errors[i]), 'error');
		} */
	}
	return Promise.reject(error);
};

export default onError;

// if (status === 401 && !errorResponse._retry && token) {
// 	store.dispatch(authInitState());
// 	notificationContainer('Session has ended', 'info');
// }
//
// if (status === 403 && !errorResponse._retry && errorName.errors.includes('user_blocked')) {
// 	notificationContainer('Error', 'error_blocked');
// }
//
// if (
// 	status === 403 &&
// 	!errorResponse._retry &&
// 	errorName.errors.includes('confirmation_is_not_finished')
// ) {
// 	notificationContainer('Error', 'not_verified');
// }
//
// if (status === 401 && !errorResponse._retry && errorName.errors.includes('user_in_the_archive')) {
// 	notificationContainer('Error', 'error_archived');
// }
//
// if (status === 400 && !errorResponse._retry && errorName.errors.includes('invalid_credentials')) {
// 	// notificationContainer('Error', 'incorrect');
// 	store.dispatch(setErrorMsg('invalid_credentials'));
// }
//
// if (status === 400 && !errorResponse._retry && errorName.errors.includes('invalid_totp_code')) {
// 	// notificationContainer('Invalid 2FA Code', 'error');
// 	store.dispatch(setErrorMsg('invalid_totp_code'));
// }
//
// if (status === 400 && !errorResponse._retry && errorName.errors.includes('2fa_already_enabled')) {
// 	notificationContainer('2FA Already enabled', 'error');
// }
//
// if (status === 400 && !errorResponse._retry && errorName.errors.includes('daily_limit')) {
// 	notificationContainer('you used the daily limit', 'error');
// }
//
// if (status !== 400 && !errorResponse._retry) {
// 	if (status === 422 && typeof errorName.errors === 'object') {
// 		Object.entries(errorName.errors).forEach((err: any) => {
// 			notificationContainer(err[1][0].replace('the', 'The').split('_').join(' '), 'error');
// 		});
// 	} else if (errorName.errors.totp.includes('the_totp_field_is_required')) {
// 		notificationContainer('Please enter 2FA code from Authenticator App', 'error');
// 	}
// }
//
// if (
// 	status === 403 &&
// 	!errorResponse._retry &&
// 	errorName.errors.includes('confirmation_is_not_finished')
// ) {
// 	notificationContainer('Error', 'not_verified');
// }
//
// if (status === 403 && errorName.errors.includes('email_not_confirmed')) {
// 	notificationContainer('Email not confirmed', 'error');
// }
