import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IDepositsApi } from './types';

// ==========================================:
// ==========================================:

export const deposits: IDepositsApi = {
	getDepositAddress: (payload) =>
		http
			.get(endpoint.deposits.DEPOSIT_ADDRESS, {
				params: payload,
			})
			.then((response) => response.data),
	getFee: (payload) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		http.post(endpoint.fee.GET_FEE, { ...payload }).then((response) => response.data),
	getFeeValue: (payload) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		http
			.get(endpoint.fee.GET_FEE_VALUE, {
				params: payload,
			})
			.then((response) => response.data),
	getSpreadFee: (payload) =>
		http.post(endpoint.fee.GET_SPREAD_FEE, { ...payload }).then((response) => response.data),
};
