import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUp } from '../types';

const VerificationErrorPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	return (
		<>
			<Popup open={open} closeOnDocumentClick onClose={closeModal}>
				<div className="modal">
					<div className="popup">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header popup-header--mb-20">
							<p className="popup-header__title red">Verification Error</p>
						</div>
						<div className="popup-icon popup-icon--type2">
							<svg
								width="58"
								height="58"
								viewBox="0 0 58 58"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M29.0013 49.8385C40.4596 49.8385 49.8346 40.4635 49.8346 29.0052C49.8346 17.5469 40.4596 8.17188 29.0013 8.17188C17.543 8.17188 8.16797 17.5469 8.16797 29.0052C8.16797 40.4635 17.543 49.8385 29.0013 49.8385Z"
									fill="#FE0000"
									stroke="#FE0000"
									strokeOpacity="0.06"
									strokeWidth="16"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M28.9931 33.5292C29.1922 33.5292 29.3608 33.4618 29.4989 33.3272C29.637 33.1926 29.7061 33.0258 29.7061 32.8267C29.7061 32.6277 29.6388 32.4591 29.5041 32.321C29.3695 32.1829 29.2027 32.1138 29.0037 32.1138C28.8047 32.1138 28.6361 32.1811 28.4979 32.3157C28.3598 32.4504 28.2907 32.6172 28.2907 32.8162C28.2907 33.0152 28.3581 33.1838 28.4927 33.322C28.6273 33.4601 28.7941 33.5292 28.9931 33.5292ZM28.3484 30.2753H29.6484V24.2753H28.3484V30.2753ZM29.007 38.0984C27.7551 38.0984 26.5761 37.8616 25.47 37.3879C24.3638 36.9142 23.3957 36.2625 22.5654 35.4326C21.7352 34.6028 21.0831 33.6354 20.6093 32.5305C20.1354 31.4255 19.8984 30.2449 19.8984 28.9886C19.8984 27.7323 20.1353 26.5553 20.6089 25.4575C21.0826 24.3597 21.7344 23.3957 22.5642 22.5654C23.394 21.7352 24.3614 21.0831 25.4664 20.6093C26.5713 20.1354 27.7519 19.8984 29.0082 19.8984C30.2645 19.8984 31.4415 20.1353 32.5393 20.6089C33.6372 21.0826 34.6012 21.7344 35.4314 22.5642C36.2616 23.394 36.9137 24.3595 37.3876 25.4605C37.8614 26.5615 38.0984 27.738 38.0984 28.9898C38.0984 30.2417 37.8616 31.4207 37.3879 32.5268C36.9142 33.633 36.2625 34.6012 35.4326 35.4314C34.6028 36.2616 33.6373 36.9137 32.5363 37.3876C31.4353 37.8614 30.2588 38.0984 29.007 38.0984ZM28.9984 36.7984C31.1651 36.7984 33.0067 36.0401 34.5234 34.5234C36.0401 33.0067 36.7984 31.1651 36.7984 28.9984C36.7984 26.8317 36.0401 24.9901 34.5234 23.4734C33.0067 21.9567 31.1651 21.1984 28.9984 21.1984C26.8317 21.1984 24.9901 21.9567 23.4734 23.4734C21.9567 24.9901 21.1984 26.8317 21.1984 28.9984C21.1984 31.1651 21.9567 33.0067 23.4734 34.5234C24.9901 36.0401 26.8317 36.7984 28.9984 36.7984Z"
									fill="white"
								/>
							</svg>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>
									There was an error submitting your information and documents. Please try again.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default VerificationErrorPopUp;
