import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeInfo } from 'redux/reducers/transactions/selectors';
import { getTradeData } from 'redux/reducers/trade/selectors';
import { changeAsset, changeAssetCount, tradeInitState } from 'redux/reducers/trade/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IPopUp } from '../types';

const SuccessTrade: FC<IPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const trade = useSelector(getTradeData);
	const tradeInfo = useSelector(getTradeInfo);
	const mainTradePair = useSelector(getTradeInfo);
	const closeHandler = () => {
		dispatch(getWalletsRequest());
		dispatch(changeAsset({}));
		dispatch(
			changeAssetCount({
				type: 'to',
				asset_count: String(0),
			}),
		);
		closeModal();
	};

	const viewTransactionsHandler = () => {
		history.push('/history/trade');
		closeModal();
	};

	const percentfee = tradeInfo?.trade_fee_percent
		? (Number(trade.from_asset_count) * Number(tradeInfo?.trade_fee_percent)) / 100
		: 0;
	const mainFee =
		percentfee && tradeInfo && percentfee > Number(tradeInfo?.trade_fee_fixed)
			? percentfee
			: tradeInfo?.trade_fee_fixed || 0;

	const receivWithoutFee = Number(tradeInfo?.price) * (Number(trade.from_asset_count) - mainFee);

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeHandler}>
			<div className="popup popup--width-660">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button className="popup__close-btn" onClick={closeHandler} />
				<p className="popup-header__title">Trade Successful</p>
				<div className="popup-icon popup-icon">
					<svg
						width="80"
						height="80"
						viewBox="0 0 80 80"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="0.5" y="0.5" width="79" height="79" rx="39.5" stroke="#5EC45C" />
						<rect x="20.5" y="20" width="40" height="40" rx="20" fill="#5EC45C" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M40.4998 30.7C35.3636 30.7 31.1998 34.8638 31.1998 40C31.1998 45.1363 35.3636 49.3 40.4998 49.3C45.6361 49.3 49.7998 45.1363 49.7998 40C49.7998 34.8638 45.6361 30.7 40.4998 30.7ZM29.7998 40C29.7998 34.0906 34.5904 29.3 40.4998 29.3C46.4093 29.3 51.1998 34.0906 51.1998 40C51.1998 45.9095 46.4093 50.7001 40.4998 50.7001C34.5904 50.7001 29.7998 45.9095 29.7998 40Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M45.9336 35.4505C46.237 35.6901 46.2888 36.1303 46.0492 36.4337L39.7334 44.4337C39.6088 44.5916 39.4221 44.6883 39.2212 44.699C39.0203 44.7097 38.8245 44.6334 38.6837 44.4896L34.9995 40.7248C34.7291 40.4485 34.7339 40.0054 35.0102 39.735C35.2865 39.4646 35.7297 39.4693 36.0001 39.7457L39.1277 42.9416L44.9504 35.5662C45.1899 35.2628 45.6301 35.211 45.9336 35.4505Z"
							fill="white"
						/>
					</svg>
				</div>
				<div className="popup-header">
					<div className="popup-text mt-24 mb-0">
						<p>You Receive</p>
					</div>

					<p className="popup-header__title success">
						{/* {popupData.data?.data.receive}{' '}
						<span className="element---uppercase">{popupData.data?.data.receiveCode}</span> */}
						{/* {roundingNumber(receivWithoutFee, trade?.to_asset_code || '')}{' '} */}
						{roundingNumber(tradeInfo?.amount, trade?.to_asset_code || '')}{' '}
						<span className="element---uppercase">{trade?.to_asset_code}</span>
					</p>
				</div>
				<div className="popup-body">
					<div className="successful-info">
						<div className="successful-info__item">
							<p>You Sell:</p>
							<span>
								{/* {popupData.data?.data.sell}{' '}
								<span className="element---uppercase">{popupData.data?.data.sellCode}</span> */}
								{/* {roundingNumber(trade.from_asset_count || '', trade?.from_asset_code || '')}{' '} */}
								{tradeInfo?.quantity &&
									trade?.from_asset_code &&
									roundingNumber(Number(tradeInfo?.quantity), trade?.from_asset_code)}{' '}
								<span className="element---uppercase">{trade?.from_asset_code}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>You Receive:</p>
							<span>
								{/* {popupData.data?.data.receive}{' '}
								<span className="element---uppercase">{popupData.data?.data.receiveCode}</span> */}
								{/* {roundingNumber(receivWithoutFee, trade?.to_asset_code || '')}{' '} */}
								{roundingNumber(tradeInfo?.amount, trade?.to_asset_code || '')}{' '}
								<span className="element---uppercase">{trade?.to_asset_code}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>Price:</p>
							<span>
								{/* 1 <span className="element---uppercase">{popupData.data?.data.priceOneCode}</span> ={' '}
								{popupData.data?.data.price}
								<span className="element---uppercase"> {popupData.data?.data.priceCode}</span> */}
								1 <span className="element---uppercase">{oneText}</span> ={' '}
								{roundingNumber(tradeInfo?.price_b2c2 || 0, twoText || '')}
								<span className="element---uppercase"> {twoText}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>Trade Fee:</p>
							<span>
								{/* {popupData.data?.data.fee}{' '}
								<span className="element---uppercase">{popupData.data?.data.feeCode}</span> */}
								{/* {roundingNumber(Number(fee), trade?.from_asset_code || '')}{' '} */}
								{roundingNumber(Number(tradeInfo?.fee), trade?.from_asset_code || '')}{' '}
								<span className="element---uppercase">{trade?.from_asset_code}</span>
							</span>
						</div>
						{/* <div className="successful-info__item">
							<p>Spread Fee:</p>
							<span>
								{popupData.data?.data.spreadFee}
								<span className="element---uppercase">{popupData.data?.data.spreadFeeCode}</span>
							</span>
						</div> */}
					</div>
				</div>

				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							onClick={viewTransactionsHandler}
							className="btn btn-primary button--full-width"
						>
							Trade History
						</button>
						<button type="button" onClick={closeHandler} className="btn btn-cancel">
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessTrade;
