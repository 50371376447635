import { FC } from 'react';
import Auth from 'layouts/Auth';
import Trade from 'components/Trade/Trade';

// ==========================================:
const TradePage: FC = () => {
	return (
		<Auth title="trade">
			<Trade />
		</Auth>
	);
};
export default TradePage;
