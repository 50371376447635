import icon from 'assets/img/no-data-table-icon.svg';

const NoDataIcon = () => {
	return (
		<div className="td__no-data">
			<img src={icon} alt="no-data-icon" />
			<p>No data to show</p>
		</div>
	);
};

export default NoDataIcon;
