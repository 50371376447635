import { toast } from 'react-toastify';
import ToastifyComponent from 'components/ToastifyComponent';
import LoginErrorNotification from 'components/ToastifyComponent/LoginErrorNotification/LoginErrorNotification';
import IncorrectNotification from 'components/ToastifyComponent/IncorrectNotification/IncorrectNotification';
import IncorrectCodeNotification from 'components/ToastifyComponent/IncorrectCodeNotification/IncorrectCodeNotification';
import BlockedNotification from 'components/ToastifyComponent/BlockedNotification/BlockedNotification';
import ArchivedNotification from 'components/ToastifyComponent/ArchivedNotification/ArchivedNotification';
import NotVerifiedNotification from 'components/ToastifyComponent/NotVerifiedNotification/NotVerifiedNotification';
import AccountNotFound from 'components/ToastifyComponent/AccountNotFound/AccountNotFound';
import UserBlocked from '../../components/ToastifyComponent/UserBlocked/UserBlocked';
import UserRejected from '../../components/ToastifyComponent/UserRejected/UserRejected';
import { toastifyConfig } from './toastifyConfig';

// ==========================================:
export const notificationContainer = (message: string, type: string, title?: string): void => {
	switch (type) {
		case 'success':
			toast.success(<ToastifyComponent message={message} title={title} />, toastifyConfig);
			break;
		case 'error':
			toast.error(<ToastifyComponent message={message} title={title} />, toastifyConfig);
			break;
		case 'user_blocked':
			toast.error(<UserBlocked message={message} title={title} />, toastifyConfig);
			break;
		case 'user_rejected':
			toast.error(<UserRejected message={message} title={title} />, toastifyConfig);
			break;
		case 'info':
			toast.info(<ToastifyComponent message={message} />, toastifyConfig);
			break;
		case 'errorLogin':
			toast.info(<LoginErrorNotification />, toastifyConfig);
			break;
		case 'incorrect':
			toast.info(<IncorrectNotification />, toastifyConfig);
			break;
		case 'incorrect_code':
			toast.info(<IncorrectCodeNotification />, toastifyConfig);
			break;
		case 'error_blocked':
			toast.info(<BlockedNotification />, toastifyConfig);
			break;
		case 'error_archived':
			toast.info(<ArchivedNotification />, toastifyConfig);
			break;
		case 'not_verified':
			toast.info(<NotVerifiedNotification />, toastifyConfig);
			break;
		case 'account_not_found':
			toast.error(<AccountNotFound title={title} message={message} />, toastifyConfig);
			break;
		default:
			break;
	}
};
