import React from 'react';
import Dashboard from 'layouts/Dashboard';
import ResetPasswordForm from 'components/ResetPassword/ResetPasswordForm/ResetPasswordForm';
import { Helmet } from 'react-helmet';
import Logo from '../../assets/img/top_logo.svg';

const ResetPassword = () => {
	return (
		<>
			<Helmet>
				<body className="login-page" />
			</Helmet>
			<Dashboard>
				<section className="login-section">
					<a href="/" className="login-section__logo">
						<img src={Logo} alt="" />
					</a>
					<div className="login">
						<div className="login-form">
							<div className="login-form__title">
								<p>Reset Password</p>
							</div>
							<ResetPasswordForm />
						</div>
					</div>
				</section>
			</Dashboard>
		</>
	);
};

export default ResetPassword;
