import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IDepositAddressResponse,
	IDepositAddressRequest,
	IDepositsStore,
	ISpreadFeeResponse,
	ISpreadFeeRequest,
} from './types';

// ==========================================:
export const initialState: IDepositsStore = {
	depositAddress: null,
	depositAddressLoader: false,
	/// ///////////////////////////
	fee: null,
	feeValue: null,
	feeLoader: false,
	spreadFee: null,
	spreadFeeLoader: false,
};

// ==========================================:
const deposits = createSlice({
	name: '@@deposits',
	initialState,
	reducers: {
		depositsInitialState: () => {
			return initialState;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		depositAddressRequest: (state, action: PayloadAction<IDepositAddressRequest>) => {
			const generateAddressState = state;
			generateAddressState.depositAddressLoader = true;
		},

		depositAddressSuccess: (state, action: PayloadAction<IDepositAddressResponse>) => {
			const { payload } = action;
			const generateAddressState = state;

			generateAddressState.depositAddressLoader = false;
			generateAddressState.depositAddress = payload;
		},
		clearDepositAddress: (state) => {
			const clearDepositAddressState = state;
			clearDepositAddressState.depositAddress = null;
		},
		feeRequest: (state, action: PayloadAction<any>) => {
			const generateAddressState = state;
			generateAddressState.feeLoader = true;
		},
		feeSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const generateAddressState = state;

			generateAddressState.fee = payload;
			generateAddressState.feeLoader = false;
		},
		feeValueRequest: (state, action: PayloadAction<any>) => {
			const generateAddressState = state;
			generateAddressState.feeLoader = true;
		},
		feeValueSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const generateAddressState = state;

			generateAddressState.feeValue = payload;
			generateAddressState.feeLoader = false;
		},
		spreadFeeRequest: (state, action: PayloadAction<ISpreadFeeRequest>) => {
			const generateSpreadFeeState = state;
			generateSpreadFeeState.spreadFeeLoader = true;
		},
		spreadFeeSuccess: (state, action: PayloadAction<ISpreadFeeResponse>) => {
			const { payload } = action;
			const generateSpreadFeeState = state;

			generateSpreadFeeState.spreadFee = payload.spread;
			generateSpreadFeeState.spreadFeeLoader = false;
		},
		depositsInitState: () => initialState,
	},
});

export default deposits.reducer;
export const {
	depositsInitialState,
	depositAddressRequest,
	depositAddressSuccess,
	feeRequest,
	feeSuccess,
	feeValueRequest,
	feeValueSuccess,
	spreadFeeRequest,
	spreadFeeSuccess,
	clearDepositAddress,
	depositsInitState,
} = deposits.actions;
