import React, { FC, useState } from 'react';
import { ILocalFilter } from '../types';
import { Datepicker } from '../Datepicker/Datepicker';

interface IProps {
	localFilter: ILocalFilter;
	changeFilter: (text: string) => void;
	filters: any;
	setFilters: any;
}

const TableHeader: FC<IProps> = ({ localFilter, changeFilter, filters, setFilters }) => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const handleSelectDate = (value: [Date | null, Date | null]) => {
		setStartDate(value[0]);
		setEndDate(value[1]);
		setFilters({ ...filters, date_from: value[0], date_to: value[1] });
	};

	const handleReset = () => {
		setStartDate(null);
		setEndDate(null);
	};

	const filterActiveUp = (text: string) => {
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					{/* <p>Timestamp</p> */}
					<Datepicker
						value={[startDate === null ? null : startDate, endDate === null ? null : endDate]}
						onChange={handleSelectDate}
					/>
				</div>
				<div className="td">
					<p>Sell</p>
				</div>
				<div className="td">
					<p>Buy</p>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('quantity');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Amount Sold
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('quantity')}`} />
							<div className={`arrow-top ${filterActiveUp('quantity')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('amount');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Amount Received
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('amount')}`} />
							<div className={`arrow-top ${filterActiveUp('amount')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('rate');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Exchange Rate
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('rate')}`} />
							<div className={`arrow-top ${filterActiveUp('rate')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Fee
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('fee')}`} />
							<div className={`arrow-top ${filterActiveUp('fee')}`} />
						</span>
					</button>
				</div>
				<div className="td td--right">
					<p>Tx ID</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
