import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import { IDatepicker } from './types';
import { CustomInput } from './CustomInput';

export const Datepicker: FC<IDatepicker> = ({ value, onChange }) => {
	const [startDate, endDate] = value;
	const changeDate = (dates: [Date, Date]) => {
		const formatStart = dates[0];
		const formatEnd = dates[1] && new Date(dates[1].setHours(23));
		onChange([formatStart, formatEnd]);
	};

	const [active, setActive] = useState(false);

	const formatDate = (rawDate: any) => {
		const date = new Date(rawDate);

		const year: any = date.getFullYear();
		let month: any = date.getMonth() + 1;
		let day: any = date.getDate();

		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		day = day < 10 ? `0${day}` : day;
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		month = month < 10 ? `0${month}` : month;

		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		return `${month}/${day}/${year}`;
	};

	return (
		<div className={`DatePicker${active ? ' is-active' : ''}`}>
			<label htmlFor="datepicker" className="select-block__name">
				Date
			</label>
			<DatePicker
				selectsRange
				startDate={startDate}
				endDate={endDate}
				onChange={changeDate}
				customInput={<CustomInput />}
				maxDate={new Date()}
				onCalendarOpen={() => setActive(true)}
				onCalendarClose={() => setActive(false)}
			>
				<div className="react-datepicker__helpers">
					<input
						type="text"
						readOnly
						placeholder="From"
						className="react-datepicker__helper-input"
						value={startDate ? formatDate(startDate) : ''}
					/>
					<div className="react-datepicker__helper-arrow" />
					<input
						type="text"
						readOnly
						placeholder="To"
						className="react-datepicker__helper-input"
						value={endDate ? formatDate(endDate) : ''}
					/>
				</div>
			</DatePicker>
		</div>
	);
};
