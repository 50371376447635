/* eslint-disable react/no-unescaped-entities */
import TermsContent from './TermsContent';

const TermsOfUse = () => {
	return (
		<section className="info-section">
			<div className="container">
				<div className="info-section__box">
					<h1>Terms and Conditions</h1>
					<span>
						By accessing or using the Kaiserex website located at{' '}
						<a target="_blank" href="https://kaiserex.com" rel="noreferrer">
							kaiserex.com
						</a>{' '}
						(the “Site”) and any services made available through the Site (collectively, the
						“Services”), you (“User”) agree to be bound by these Terms of Use (“Terms”). The
						Services are owned and controlled by UAB Kaiser Exchange International, (“Kaiserex”),
						and the terms “we,” “us,” and “our” refer to UAB Kaiser Exchange International. These
						Terms affect your legal rights and obligations. If you do not agree to be bound by these
						Terms, do not access or use the Services. Kaiserex reserves the right to change or
						modify the terms and conditions contained in these Terms, including but not limited to
						any policy or guideline of the Site, at any time and at its sole discretion. We will
						provide notice of these changes by posting the revised Terms to the Site and changing
						the "Last Revised" date at the top of the Terms, or by emailing users at their provided
						email addresses, or by any other means as determined by Kaiserex. The decision of which
						notification chosen will be left to Kaiserex's sole discretion. Using a particular form
						of notice in some instances does not obligate us to use the same form in other
						instances. Any changes or modifications will be effective immediately upon posting the
						revisions to the Site or at the instant that Kaiserex transmits the information to the
						users (e.g. via email). These changes will apply at that instant to all then current and
						subsequent uses of the Site. You waive any right you may have to receive specific notice
						of such changes or modifications. Your continued use of this Site acts as acceptance of
						such changes or modifications. If you do not agree to the Terms in effect when you
						access or use the Site, you must stop using the Site.
					</span>
					{TermsContent.map((item) => {
						return (
							<div key={item.id}>
								<p>{item.title}</p>
								<span>{item.content && item.content}</span>
							</div>
						);
					})}

					<div className="info-section__date">
						<p>Last revision: March 01, 2017</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TermsOfUse;
