import { IStoreState } from 'redux/types';
import { createSelector } from '@reduxjs/toolkit';
import { IDownloadStore } from './types';
// ==========================================:
const getDownloadState = (state: IStoreState): IDownloadStore => state.download;
export const getDownloadKey = createSelector(
	[getDownloadState],
	(download: IDownloadStore) => download.downloadKey,
);
// ====================================================:
export const getDownloadKeyLoading = createSelector(
	[getDownloadState],
	(download: IDownloadStore) => download.downloadKeyLoading,
);
