import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import { resetTwoFaRequest } from 'redux/reducers/auth/reducer';
import ReCAPTCHA from 'react-google-recaptcha';
import { IResetTwoFaRequestPayload } from 'redux/reducers/auth/types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { notificationsMessagesError } from '../../../services/utils/notificationsMessages/notificationsMessagesError';

interface IForm {
	email: string;
	reason: string;
}

const ResetTwoFaForm = () => {
	const dispatch = useDispatch();
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const onSubmit = (obj: IResetTwoFaRequestPayload) => {
		if (!reCaptchaKey) {
			notificationContainer(notificationsMessagesError.the_captcha_field_is_required, 'error');
		} else {
			dispatch(resetTwoFaRequest(obj));
		}
	};

	const initialValues = {
		email: '',
		reason: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.email.required)
			.email(notificationsInfoFields.email.invalid)
			.max(60, notificationsInfoFields.email.maxLength),
		reason: yup
			.string()
			.required('Please explain reason for 2FA reset.')
			.max(120, 'Reason is too long. Maximum 120 characters.'),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForm, { setSubmitting, resetForm }) => {
				onSubmit({
					...values,
					captcha: reCaptchaKey || '',
					type: 1,
				});
				setSubmitting(false);
				reCaptchaRef?.current?.reset();
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting }) => (
				<Form className="form">
					<div className="login-form-item">
						<div className="input input--mb-0">
							<p className="input__name">Email</p>
							<div className="input-wrapper">
								<Field type="email" placeholder="Email" name="email" required component={Input} />
							</div>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input input--mb-0">
							<p className="input__name">Reason</p>
							<div className="input-wrapper">
								<Field
									type="textarea"
									placeholder="Reason"
									name="reason"
									required
									component={Input}
								/>
							</div>
						</div>
					</div>

					<div className="login-recaptcha">
						<ReCAPTCHA
							ref={reCaptchaRef}
							onChange={onReCaptchaChange}
							sitekey={siteKey || ''}
							hl="en"
							theme="dark"
						/>
					</div>

					<div className="login-form-item">
						<button
							disabled={isSubmitting}
							className="btn btn-primary btn--full"
							aria-label="form-submit"
							type="submit"
						>
							Confirm
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ResetTwoFaForm;
