export interface ICompanyStore {
	company_data: ICompanyData;
	active_step: CompanyStepsEnum;
	loading: boolean;
}

export interface ICompanyData
	extends ICorporateInformation,
		ITradingActivities,
		IWealthBusinessIncome,
		IWealthEmploymentIncome,
		IWealthSavings,
		IWealthGiftOrInheritance,
		IWealthExternalFunding,
		IWealthProfitFromInvestments,
		IWealthProfitFromPropertySale,
		IWealthOther,
		IFunds {}

export interface ICorporateInformation {
	website: string;
	company_name: string;
	registration_number: string;
	registration_country: string;
}

export interface ITradingActivities {
	trading_frequency: TradingFrequencyEnum;
	trading_activities: TradingActivitiesEnum;
	average_transaction_size: AverageTransactionSizeEnum;
}

export interface IWealthBusinessIncome {
	business_income: string | number;
	business_date: string;
	business_name: string;
	business_amount: number;
}

export interface IWealthEmploymentIncome {
	employment_income: string | number;
	employment_income_employer: string;
	employment_income_occupation: string;
	employment_income_gross_annual_salary: number;
}

export interface IWealthSavings {
	savings: string | number;
	savings_date: string;
	savings_currency: string;
	savings_balance: number;
}

export interface IWealthGiftOrInheritance {
	gift_or_inheritance: string | number;
	gift_or_inheritance_date: string;
	gift_or_inheritance_benefactor: string;
	gift_or_inheritance_amount: number;
}

export interface IWealthExternalFunding {
	external_funding: string | number;
	external_funding_date: string;
	external_funding_investor: string;
	external_funding_amount: number;
}

export interface IWealthProfitFromInvestments {
	profit_from_investments: string | number;
	profit_from_investments_investment_date: string;
	profit_from_investments_investment_type: string;
	profit_from_investments_investment_amount: number;
}

export interface IWealthProfitFromPropertySale {
	profit_from_property_sale: string | number;
	profit_from_property_sale_date: string;
	profit_from_property_sale_location: string;
	profit_from_property_sale_amount: number;
}

export interface IWealthOther {
	other: string | number;
	other_details: string;
}

export interface IFunds {
	token: string;
	bank_name: string;
	swift_bic: string;
	bank_address: string;
	beneficiary_name: string;
	account_number: string;
	beneficiary_address: string;
	bank_currency: string;
	other_bank_currency: string;
	cryptocurrency_wallet_address: string;
	cryptocurrency: string;
}

export enum CompanyStepsEnum {
	CORPORATE_INFORMATION = 'corporate_information',
	TRADING_ACTIVITIES = 'trading_activities',
	SOURCE_OF_WEALTH = 'source_of_wealth',
	SOURCE_OF_FUNDS = 'source_of_funds',
}

export enum TradingActivitiesEnum {
	BUY = 'buy',
	SELL = 'sell',
	BOTH = 'both',
	DEFAULT = 'default',
}

export enum TradingFrequencyEnum {
	FREQUENT = 'frequent',
	REGULAR = 'regular',
	OCCASIONAL = 'occasional',
	RARE = 'rare',
	DEFAULT = 'default',
}

export enum AverageTransactionSizeEnum {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
	ENTERPRISE = 'enterprise',
	DEFAULT = 'default',
}
