import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IConfirmationStore } from './types';

// ==========================================:
const getConfirmationState = (state: IStoreState): IConfirmationStore => state.confirmation;
export const getConfirmation = createSelector(
	[getConfirmationState],
	(confirmation: IConfirmationStore) => confirmation,
);

// ====================================================:

export const getConfirmationData = createSelector(
	[getConfirmation],
	(confirmation: IConfirmationStore) => confirmation,
);
export const getConfirmationTwoFaQRData = createSelector(
	[getConfirmation],
	(confirmation: IConfirmationStore) => confirmation.confirmationGenerateTwoFa,
);
