import { createSelector } from '@reduxjs/toolkit';
import { ICompanyStore } from './types';
import { IStoreState } from '../../types';

// ==========================================:
const getAuthState = (state: IStoreState): ICompanyStore => state.company;
export const getCompanyStore = createSelector([getAuthState], (company: ICompanyStore) => company);

// ====================================================:
export const getCompanyData = createSelector(
	[getAuthState],
	(company: ICompanyStore) => company.company_data,
);
// ====================================================:
export const getCompanyStep = createSelector(
	[getAuthState],
	(company: ICompanyStore) => company.active_step,
);
// ====================================================:
export const getCompanyLoading = createSelector(
	[getAuthState],
	(company: ICompanyStore) => company.loading,
);
