export enum ApiUserStatus {
	Unverified = 1,
	Verified = 2,
	Pending = 3,
	Rejected = 4,
}

export const statusNames: Record<number, string> = {
	[ApiUserStatus.Unverified]: 'Unverified',
	[ApiUserStatus.Verified]: 'Verified',
	[ApiUserStatus.Pending]: 'Pending',
	[ApiUserStatus.Rejected]: 'Rejected',
};

export const statusClassNames: Record<number, string> = {
	[ApiUserStatus.Unverified]: 'red-bg',
	[ApiUserStatus.Verified]: 'green-bg',
	[ApiUserStatus.Pending]: 'yellow-bg',
	[ApiUserStatus.Rejected]: 'red-bg',
};
