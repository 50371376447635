import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IConfirmationApi } from './types';
// ==========================================:

export const confirmation: IConfirmationApi = {
	confirmationPassword: (payload) =>
		http.post(endpoint.confirmation.CONFIRMATION_PASSWORD, payload).then((response) => {
			return response.data;
		}),
	confirmationTwoFa: (params) =>
		http
			.get(endpoint.confirmation.CONFIRMATION_TWO_FA, { params })
			.then((response) => response.data),
	confirmationTwoFaRegistration: (payload) =>
		http.post(endpoint.confirmation.CONFIRMATION_ENABLE_CHANGE_TWO_FA, payload).then((response) => {
			return response.data;
		}),
	confirmationEmail: (payload) =>
		http.post(endpoint.confirmation.CONFIRMATION_EMAIL, payload).then((response) => {
			return response.data;
		}),
	confirmationDocuments: (payload) =>
		http.post(endpoint.confirmation.CONFIRMATION_DOCUMENTS, payload).then((response) => {
			return response.data;
		}),
	updateCompanyData: (payload) =>
		http.post(endpoint.confirmation.COMPANY, payload).then((response) => {
			return response.data;
		}),
};
