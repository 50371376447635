import { FC } from 'react';
// import toastifyError from 'assets/img/icons/toastify_error.svg';
import toastifyErrorHeading from 'assets/img/icons/toast_error.svg';
import { IToastifyComponent } from '../types';
// ==========================================:

const AccountNotFound: FC<IToastifyComponent> = ({ message, toastProps, title }) => {
	return (
		<>
			<div className="toastify-component">
				<div className="toastify-component__heading">
					<img src={toastifyErrorHeading} width="30" height="30" alt="" />
					<p>{title}</p>
				</div>
				<div className="toastify-component__content">{message}</div>
				<button
					type="button"
					className="toastify-component__btn-light"
					onClick={() => {
						const supportButton = document.getElementById('footer_support_btn');
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						supportButton.click();
						toastProps.closeToast();
					}}
				>
					Support
				</button>
			</div>
		</>
	);
};

export default AccountNotFound;
