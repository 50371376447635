import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';

import Auth from 'layouts/Auth';
import IconSvg from 'ui/Svg/IconSvg';
import Input from 'ui/Formik/Input';
import { getWalletsCryptoList } from 'redux/reducers/wallets/selectors';
import { IWalletAddresses, IWalletItem } from 'redux/reducers/wallets/types';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import WalletSelect from 'ui/Formik/Select/WalletSelect';
import WalletAddressSelect from 'ui/Formik/Select/WalletAddressSelect';
import ConfirmCryptoWithdrawal from 'layouts-elements/PopUp/ConfirmCryptoWithdrawal/ConfirmCryptoWithdrawal';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import AddWalletAddress from 'layouts-elements/PopUp/AddWalletAddress/AddWalletAddress';
import SuccessCryptoWithdrawal from 'layouts-elements/PopUp/SuccessCryptoWithdrawal/SuccessCryptoWithdrawal';
import { getFees } from 'redux/reducers/currency/selectors';
import { feeDataRequest } from 'redux/reducers/currency/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { getFee } from 'redux/reducers/deposits/selectors';
import { feeRequest } from 'redux/reducers/deposits/reducer';
import HeaderActions from 'layouts-elements/HeaderActions';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const WithdrawCryptoPage = () => {
	const dispatch = useDispatch();
	const walletsCrypto = useSelector(getWalletsCryptoList);
	const fees = useSelector(getFees);
	const [selectedWallet, setSelectedWallet] = useState<IWalletItem | null>(null);
	const [selectedWalletAddress, setSelectedWalletAddress] = useState<IWalletAddresses | null>(null);
	const [walletsAddressList, setWalletAddressList] = useState<IWalletAddresses[] | undefined>();
	const [resetWalletSelect, setResetWalletSelect] = useState(false);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [openModalAddAddress, setOpenModalAddAddress] = useState(false);
	const [check, setCheck] = useState(false);
	const closeModalConfirm = useCallback(() => {
		setOpenModalConfirm(false);
	}, []);

	const showModalSuccess = () => {
		setOpenModalSuccess(true);
	};

	const handleChangeSelectedWallet = (wallet: IWalletItem) => {
		setSelectedWalletAddress(null);
		setSelectedWallet(wallet);
	};

	const showModalAddAddress = useCallback(() => {
		if (selectedWallet?.id) setOpenModalAddAddress(true);
	}, [selectedWallet?.id]);

	const closeModalAddAddress = useCallback(() => {
		setOpenModalAddAddress(false);
	}, []);

	const withdrawMin = Number(selectedWallet?.asset.withdraw_min);
	const withdrawMax =
		Number(selectedWallet?.balance) < Number(selectedWallet?.asset.withdraw_max)
			? Number(selectedWallet?.balance)
			: Number(selectedWallet?.asset.withdraw_max);
	const code = useMemo(() => {
		return selectedWallet?.asset.code.toUpperCase() || '';
	}, [selectedWallet?.asset.code]);
	const assetName = selectedWallet?.asset.name || '';

	const getCalcAmount = useCallback(
		(value: string) => {
			const numValue = Number(value);
			let percent = 0;
			let receivedFee = 0;
			if (selectedWallet && fees) {
				percent = fees[selectedWallet?.asset.id].withdraw_fee_percent;
				const percentFix = fees[selectedWallet?.asset.id].withdraw_fee_fixed;
				receivedFee = (percent * numValue) / 100;
				if (receivedFee < percentFix) {
					receivedFee = percentFix;
				}
			}
			const fee = receivedFee > 0 ? `${receivedFee.toFixed(6)}` : '';
			const receive = String(numValue ? numValue - receivedFee : 0);
			return { fee, receive };
		},
		[fees, selectedWallet],
	);

	const initialValues = {
		wallet: '',
		address: '',
		amount: '',
	};

	const validationSchema = yup.object().shape({
		wallet: yup.string().required(notificationsInfoFields.wallet.required),
		address: yup.string().required(notificationsInfoFields.address.required),
		amount: yup
			.number()
			.required(notificationsInfoFields.amount.required)
			.min(withdrawMin, `${notificationsInfoFields.amount.min} ${withdrawMin}`)
			.max(withdrawMax, `${notificationsInfoFields.amount.max} ${withdrawMax}`),
	});

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(feeDataRequest());
		if (localStorage.asset && walletsCrypto) {
			const currentCoin =
				walletsCrypto.find(
					(el: IWalletItem) =>
						el.id === Number(JSON.parse(localStorage.asset).asset_id) && el.asset.withdrawable,
				) || null;

			setSelectedWallet(currentCoin);
		}
		return () => {
			localStorage.removeItem('asset');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedWallet) setResetCustomSelect(true);

		if (selectedWallet?.id) {
			const currentWallet = walletsCrypto?.find((el) => el.id === selectedWallet.id);
			const addressList = currentWallet?.address.filter((el) => el.type === 'withdrawal');
			setWalletAddressList(addressList);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedWallet, check]);
	// eslint-disable-next-line no-debugger
	const fee = useSelector(getFee);
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<div className="wallet-operations">
						<div className="wallet-operations-header-wrap">
							<div className="wallet-operations-header">
								<Link to="/wallets" className="wallet-operations-header__back">
									<IconSvg name="arrow-left" w="24" h="24" />
								</Link>
								<p>Withdraw Crypto</p>
							</div>
							<div className="wallet-operations-header-right">
								<Link to="/withdrawal" className="button button--size2 button--type2">
									Withdraw Fiat
								</Link>
								<Link to="/withdrawal" className="wallet-operations-header__back">
									<IconSvg name="arrow-right" w="24" h="24" />
								</Link>
							</div>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={() => {
								setOpenModalConfirm(true);
							}}
						>
							{({ values, resetForm }) => {
								const amount = getCalcAmount(values.amount);
								const clearData = () => {
									setOpenModalSuccess(false);
									setResetWalletSelect(true);
									setSelectedWallet(null);
									resetForm();
								};
								if (Number(values.amount) !== 0) {
									const payload = {
										asset_id: selectedWallet?.asset.id,
										type: 'withdraw',
										amount: values.amount,
									};
									dispatch(feeRequest(payload));
								}
								return (
									<Form className="form">
										<div className="wallet-operations-item coin__text-normal">
											<div className="wallet-operations-item__title">
												<span>1</span>
												<p>Select Cryptocurrency</p>
											</div>
											<Field
												type="text"
												placeholder="Select Coin"
												dropdownTitle="Select Coin"
												name="wallet"
												required
												component={WalletSelect}
												arr={walletsCrypto?.filter((el) => el.asset.withdrawable)}
												onChange={handleChangeSelectedWallet}
												resetCustomSelect={resetWalletSelect}
												setResetCustomSelect={setResetWalletSelect}
												activeValue={selectedWallet || undefined}
											/>
										</div>
										<div
											onClick={() => {
												setCheck(!check);
												dispatch(getWalletsRequest);
											}}
											className="wallet-operations-item"
										>
											<div className="wallet-operations-item__title">
												<span>2</span>
												<p>Select Wallet Address</p>
											</div>
											<Field
												type="text"
												name="address"
												dropdownTitle="Select Wallet Addresses"
												placeholder="Saved Wallet Addresses"
												component={WalletAddressSelect}
												arr={walletsAddressList}
												resetCustomSelect={resetCustomSelect}
												setResetCustomSelect={setResetCustomSelect}
												buttonName="Add Wallet Addresses"
												buttonClick={showModalAddAddress}
												onChange={setSelectedWalletAddress}
												disabled={!code}
											/>
										</div>
										<div className="wallet-operations-item amount-input">
											<div className="wallet-operations-item__title">
												<span>3</span>
												<p>Withdrawal Amount</p>
											</div>
											<Field
												type="number"
												placeholder={
													selectedWallet
														? `Enter ${roundingNumber(withdrawMin, code)} - ${roundingNumber(
																withdrawMax,
																code,
														  )}`
														: ''
												}
												name="amount"
												required
												disabled={!code}
												component={Input}
												addonRight={code}
												buttonMax={withdrawMax}
											/>
											{!!selectedWallet?.balance && (
												<div className="input__notification input__notification--type4">
													<p>
														Available Balance:{' '}
														<span className="fz-16">
															{roundingNumber(selectedWallet.balance, code)} {code.toUpperCase()}
														</span>
													</p>
												</div>
											)}
										</div>

										<div className="wallet-operations-item">
											<div className="wallet-operations-item__title">
												<span>4</span>
												<p>Withdrawal Confirmation</p>
											</div>
											<div className="receive-amount">
												{values.amount ? (
													<p>
														{roundingNumber(
															Number(values.amount) -
																Number(roundingNumber(Number(fee), code).replace(',', '')),
															code,
														)}{' '}
														{code}
													</p>
												) : (
													<p>
														{0} {code}
													</p>
												)}
												<span>
													Withdrawal Fee:{' '}
													{values.amount ? (
														<strong>
															{roundingNumber(Number(fee), code)} {code.toUpperCase()}
														</strong>
													) : (
														<strong>
															{0} {code.toUpperCase()}
														</strong>
													)}
												</span>
											</div>

											<button
												type="submit"
												className="button button--full-width"
												// disabled={!(isValid && dirty)}
											>
												Withdraw
											</button>
											<ConfirmCryptoWithdrawal
												open={openModalConfirm}
												closeModal={closeModalConfirm}
												clearParentForm={clearData}
												openSuccess={showModalSuccess}
												amount={values.amount}
												fee={Number(fee)}
												address={values.address}
												network={selectedWallet?.asset?.code || ''}
												code={code}
												assetName={assetName}
												asset_id={selectedWallet?.asset.id}
												chain_id={selectedWalletAddress?.chain_id}
												chain={selectedWalletAddress?.chain}
												address_id={selectedWalletAddress?.id}
											/>
											<SuccessCryptoWithdrawal
												open={openModalSuccess}
												closeModal={clearData}
												amount={values.amount}
												fee={Number(fee)}
												address={values.address}
												network={selectedWallet?.asset?.code || ''}
												chain={selectedWalletAddress?.chain}
												code={code}
												assetName={assetName}
												clearParentForm={() => console.log('clear')}
											/>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
					{selectedWallet?.id && (
						<AddWalletAddress
							open={openModalAddAddress}
							closeModal={closeModalAddAddress}
							walletId={selectedWallet?.id}
							asset_id={selectedWallet?.asset.id}
							create
						/>
					)}
				</div>
			</section>
		</Auth>
	);
};

export default WithdrawCryptoPage;
