import React, { FC, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { navList } from '../../../routes/routesList';

export interface IHistoryHeader {
	title?: string;
}

const HistoryHeader: FC<IHistoryHeader> = ({ title }) => {
	const [path, setPath] = useState('');

	const { pathname } = useLocation();

	useEffect(() => {
		const pathName = pathname.split('/');
		if (pathName[pathName.length - 1].startsWith('deposit')) {
			setPath('deposit');
		}
		if (pathName[pathName.length - 1].startsWith('withdrawal')) {
			setPath('withdrawal');
		}
	}, [pathname]);

	return (
		<div className="wallet-operations-header-history">
			<div className="wallet-content-header wallet-content-header">
				<div className="wallet-content-header__title">{title || <p>Deposits & Withdrawals</p>}</div>
				{/* <div className="wallet-content-header__buttons flex flex-e flex-m">
					<Link to={navList.trade.path} className="btn btn-primary">
						Trade
					</Link>
					<Link to={navList.depositFiat.path} className="btn btn-primary btn-primary--blue300">
						Deposit
					</Link>
					<Link to={navList.withdrawFiat.path} className="btn btn-primary btn-primary--blue100">
						Withdraw
					</Link>
				</div> */}
			</div>

			<div className="tabs-buttons tabs-buttons--mb-0">
				<NavLink to={`${path}-crypto`}>Crypto</NavLink>
				<NavLink to={`${path}-fiat`}>Fiat</NavLink>
			</div>
		</div>
	);
};

export default HistoryHeader;
