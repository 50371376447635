import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import {
	getWalletsCryptoList,
	getWalletsFiatList,
} from '../../../redux/reducers/wallets/selectors';
import TableItem from '../TableItem/TableItem';

const WalletBalances = () => {
	const walletsCryptoList = useSelector(getWalletsCryptoList);
	const walletsFiatList = useSelector(getWalletsFiatList);

	const [activeTab, setActiveTab] = useState('crypto');

	return (
		<div className="trade-wallet-balances">
			<div className="trade-wallet-balances__tabs-wrap">
				<div className="trade-wallet-balances__tabs">
					<button
						type="button"
						className={`trade-wallet-balances__tab ${activeTab === 'fiat' ? 'active' : ''}`}
						onClick={() => setActiveTab('fiat')}
					>
						Fiat
					</button>
					<button
						type="button"
						className={`trade-wallet-balances__tab ${activeTab === 'crypto' ? 'active' : ''}`}
						onClick={() => setActiveTab('crypto')}
					>
						Crypto
					</button>
				</div>
			</div>
			<div className="trade-wallet-balances-table__scroll-wrap">
				<div className="trade-wallet-balances-table__scroll">
					<div className="trade-wallet-balances-table">
						<AnimatePresence>
							{activeTab === 'fiat' && (
								<motion.div
									className="trade-wallet-balances-table__wrapper"
									initial={{ opacity: 0 }}
									animate={{ opacity: 1, transition: { duration: 0.5 } }}
									exit={{ opacity: 0, transition: { duration: 0 } }}
								>
									{!!walletsFiatList?.length &&
										walletsFiatList.map((wallet: any) => <TableItem key={wallet.id} {...wallet} />)}
								</motion.div>
							)}
						</AnimatePresence>
						<AnimatePresence>
							{activeTab === 'crypto' && (
								<motion.div
									className="trade-wallet-balances-table__wrapper"
									initial={{ opacity: 0 }}
									animate={{ opacity: 1, transition: { duration: 0.5 } }}
									exit={{ opacity: 0, transition: { duration: 0 } }}
								>
									{!!walletsCryptoList?.length &&
										walletsCryptoList.map((wallet: any) => (
											<TableItem key={wallet.id} {...wallet} />
										))}
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletBalances;
