import PrivacyContent from './PrivacyContent';

const PrivacyPolicy = () => {
	return (
		<section className="info-section">
			<div className="container ">
				<div className="info-section__box">
					<h1>Privacy Policy</h1>
					{PrivacyContent.map((item) => {
						return (
							<div key={item.id}>
								<p>{item.title}</p>
								<span>{item.content && item.content}</span>
							</div>
						);
					})}
					<div className="info-section__date">
						<p>Last revision: June 12, 2016</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PrivacyPolicy;
