import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	confirmationPasswordRequest,
	confirmationPasswordSuccess,
	confirmationInitState,
	confirmationTwoFaSuccess,
	confirmationTwoFaRequest,
	confirmationTwoFaCodeSuccess,
	confirmationTwoFaCodeRequest,
	confirmationEmailSuccess,
	confirmationEmailRequest,
	confirmationDocumentsRequest,
	confirmationDocumentsSuccess,
	confirmationGenerateTwoFaRequest,
	confirmationGenerateTwoFaSuccess,
	confirmationSendCodeRequest,
	confirmationSendCodeSuccess,
} from './reducer';
import { IRequestPayloadData, ITwoFaResponse } from './types';
import { changeSteps } from '../steps/reducer';
import { popUpOpen } from '../popUp/reducer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { clearError } from '../errors/reducer';

// =============================================================:
// =============================================================:
function* confirmationPasswordWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.confirmation.confirmationPassword, payload);
		// notificationContainer(notificationsMessagesInfo.passwordSuccessfully, 'success');
		yield put(confirmationPasswordSuccess());
		payload.history.push(`/registration/twoFa/`);
	} catch (error) {
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* confirmationTwoFaWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITwoFaResponse = yield call(api.confirmation.confirmationTwoFa, payload);
		// notificationContainer('Two fa success', 'success');
		yield put(confirmationTwoFaSuccess(response));
	} catch (error) {
		// console.log();
	} finally {
		yield put(hideLoading());
	}
}
function* confirmationTwoFaCodeWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(clearError());
		yield put(showLoading());
		yield call(api.confirmation.confirmationTwoFaRegistration, payload);
		// notificationContainer('Successful registration', 'success');
		payload.history.push('/login');
		yield put(popUpOpen('successLogin'));
		yield put(confirmationTwoFaCodeSuccess());
	} catch (error) {
		yield put(showLoading());
	} finally {
		yield put(hideLoading());
	}
}

function* confirmationEmailWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.confirmation.confirmationPassword, payload);
		notificationContainer(notificationsMessagesInfo.emailSuccessfully, 'success');
		yield put(confirmationEmailSuccess());
	} catch (error) {
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* confirmationDocumentsWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.confirmation.confirmationDocuments, payload);
		notificationContainer(notificationsMessagesInfo.documentsSent, 'success');
		payload.history.push('/registration/two-fa-setup');
		yield put(changeSteps({ id: 5, active_step: 'Two fa setup' }));

		yield put(confirmationDocumentsSuccess());
	} catch (error) {
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* confirmationGenerateTwoFaWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITwoFaResponse = yield call(api.confirmation.confirmationTwoFa, payload);
		notificationContainer(notificationsMessagesInfo.generationSuccessful, 'success');
		// payload.history.push('/registration/two-fa-setup');
		// yield put(changeSteps({ id: 5, active_step: 'Two fa setup' }));
		yield put(confirmationGenerateTwoFaSuccess(response));
	} catch (error) {
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* confirmationSendTwoFaWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITwoFaResponse = yield call(
			api.confirmation.confirmationTwoFaRegistration,
			payload,
		);

		// notificationContainer('Registration completed', 'success');
		yield put(popUpOpen('successRegistration'));
		yield put(confirmationSendCodeSuccess(response));
	} catch (error) {
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* confirmationSaga() {
	yield takeEvery(confirmationPasswordRequest.type, confirmationPasswordWorker);
	yield takeEvery(confirmationTwoFaRequest.type, confirmationTwoFaWorker);
	yield takeEvery(confirmationTwoFaCodeRequest.type, confirmationTwoFaCodeWorker);
	yield takeEvery(confirmationEmailRequest.type, confirmationEmailWorker);
	yield takeEvery(confirmationDocumentsRequest.type, confirmationDocumentsWorker);
	yield takeEvery(confirmationGenerateTwoFaRequest.type, confirmationGenerateTwoFaWorker);
	yield takeEvery(confirmationSendCodeRequest.type, confirmationSendTwoFaWorker);
}
