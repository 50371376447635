import React, { FC } from 'react';
import { IPaymentMethodsHeader } from './types';

const PaymentMethodsHeader: FC<IPaymentMethodsHeader> = ({ title, buttonText, buttonHandler }) => {
	return (
		<div className="wallet-operations-header-wrap">
			<div className="wallet-operations-header">
				<p className="wallet-operations-title">{title}</p>
			</div>
			<div className="wallet-operations-header-right">
				<button type="button" className="btn btn-primary btn--small" onClick={buttonHandler}>
					<svg
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6.53302 0.666732C6.53302 0.37218 6.29424 0.133399 5.99969 0.133399C5.70514 0.133399 5.46636 0.37218 5.46636 0.666732L5.46636 5.46662L0.666488 5.46662C0.371936 5.46662 0.133155 5.7054 0.133155 5.99996C0.133155 6.29451 0.371936 6.53329 0.666488 6.53329L5.46636 6.53329L5.46636 11.3334C5.46636 11.6279 5.70514 11.8667 5.99969 11.8667C6.29424 11.8667 6.53302 11.6279 6.53302 11.3334L6.53302 6.53329L11.3332 6.53329C11.6277 6.53329 11.8665 6.29451 11.8665 5.99996C11.8665 5.7054 11.6277 5.46662 11.3332 5.46662L6.53302 5.46662L6.53302 0.666732Z"
							fill="white"
						/>
					</svg>
					{buttonText}
				</button>
			</div>
		</div>
	);
};

export default PaymentMethodsHeader;
