import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

export interface IPagination {
	pageCount: number;
	onPageChange: (selected: { selected: number }) => void;
	forcePage: number;
}

const Pagination: FC<IPagination> = ({ pageCount, onPageChange, forcePage }) => {
	return (
		<ReactPaginate
			breakLabel="..."
			onPageChange={onPageChange}
			pageCount={pageCount}
			forcePage={forcePage - 1}
			className="pagination"
			activeClassName="active"
			previousClassName="pagination__arrow pagination__arrow--prev"
			nextClassName="pagination__arrow pagination__arrow--next"
			previousLabel=""
			nextLabel=""
		/>
	);
};

export default Pagination;
