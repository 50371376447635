import React from 'react';
import icon from 'assets/img/no-data-table-icon.svg';

const TableBodyNoData = () => {
	return (
		<div className="payment-methods-body">
			<div className="payment-methods-body-plug">
				<img src={icon} alt="no-data-icon" />
				<p>No data to show</p>
			</div>
		</div>
	);
};

export default TableBodyNoData;
