export interface ISelected {
	changeSelectedItem: (item: any) => void;
	selectedItemsArray: [string, string];
	text: string;
}

export const selectedItemsArrayType = [
	{ id: 1, text: 'withdrawal' },
	{ id: 2, text: 'deposit' },
];
export const selectedItemsArrayStatus = [
	{ id: 1, text: 'completed' },
	{ id: 2, text: 'failed' },
	{ id: 3, text: 'pending' },
];
