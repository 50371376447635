export const notificationsMessagesInfo = {
	loginSuccess: `You've logged in successfully.`,
	logout: 'Please log in in order to access the platform.',
	CompanySaved: 'Company information has been saved.',
	registeredSuccess: 'You have successfully registered.',
	documentsSent: 'Documents sent.',
	twoFaSuccess: '2FA successful.',
	passwordSuccessfully: 'Password has been changed successfully.',
	emailSuccessfully: 'Email address has been changed successfully.',
	generationSuccessful: 'Generation has been completed successfully.',
	reset2FASuccess: 'We have just sent you an email message.',
	supportSuccess: 'We’ll get back to you shortly.',
};
