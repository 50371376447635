import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWalletAddresses,
	getWalletAddressesPageInfo,
	getWalletAddressesPagesCount,
} from 'redux/reducers/walletAddresses/selectors';
import {
	getWalletAddressesRequest,
	changeWalletAddressesPage,
	deleteWalletAddressRequest,
} from 'redux/reducers/walletAddresses/reducer';
import Pagination from 'components/Pagination/Pagination';
import TableHeader from 'components/PaymentMethods/PaymentMethodsTable/TableHeader/TableHeader';
import TableBodyNoData from 'components/PaymentMethods/PaymentMethodsTable/TableBodyNoData/TableBodyNoData';
import TableBodyWalletAddress from 'components/PaymentMethods/PaymentMethodsTable/TableBodyWalletAddress/TableBodyWalletAddress';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';

export interface IBankAccountsList {
	onUpdate: (id: number) => void;
}
const WalletAddressesList: FC<IBankAccountsList> = ({ onUpdate }) => {
	const walletAddressesData = useSelector(getWalletAddresses);
	const walletAddressesPageInfo = useSelector(getWalletAddressesPageInfo);
	const walletAddressesPagesCount = useSelector(getWalletAddressesPagesCount);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			changeWalletAddressesPage({
				per_page: 5,
				current_page: 1,
			}),
		);
		dispatch(currencyDataRequest());
		dispatch(getWalletAddressesRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const newPageInfo = { ...walletAddressesPageInfo, current_page: selected + 1 };
		dispatch(changeWalletAddressesPage(newPageInfo));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your wallet address "${nickname}" was deleted successfully.`,
				confirmButtonTitle: 'Close',
			}),
		);
		const payload: { id: number; page?: number } = { id };
		if (walletAddressesData.length === 1) {
			payload.page = walletAddressesPageInfo.current_page - 1;
		}
		dispatch(deleteWalletAddressRequest(payload));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Wallet Address',
				message: 'Are you sure you want to delete the address?',
				confirmHandler: () => onDelete(id, nickname),
				confirmButtonTitle: 'Delete',
			}),
		);
		dispatch(popUpOpen('warningPopUp'));
	};

	// useEffect(() => {
	// 	if (
	// 		walletAddressesPageInfo.current_page >
	// 		Math.ceil(walletAddressesPagesCount / walletAddressesPageInfo.per_page)
	// 	) {
	// 		const newPageInfo = {
	// 			...walletAddressesPageInfo,
	// 			current_page: walletAddressesPageInfo.current_page - 1,
	// 		};
	// 		dispatch(changeWalletAddressesPage(newPageInfo));
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [walletAddressesPageInfo]);

	/* useEffect(() => {
		console.log(walletAddressesData);
	}, [walletAddressesData]); */

	return (
		<>
			{walletAddressesData.length ? (
				<>
					{walletAddressesData.map((el) => {
						return (
							<div className="payment-methods" key={el.id}>
								<TableHeader
									onUpdate={() => onUpdate(el.id)}
									onDelete={() => deleteHandler(el.id, el.nickname)}
								>
									<p>{el.nickname}</p>
								</TableHeader>
								<TableBodyWalletAddress {...el} />
							</div>
						);
					})}

					{walletAddressesPagesCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={walletAddressesPagesCount}
								onPageChange={handlePageClick}
								forcePage={walletAddressesPageInfo.current_page}
							/>
						</div>
					)}
				</>
			) : (
				<div className="payment-methods">
					<TableHeader empty /> <TableBodyNoData />
				</div>
			)}
		</>
	);
};

export default WalletAddressesList;
