import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getTwoFaStatusRequest } from 'redux/reducers/auth/reducer';
import { disableTwoFaRequest } from 'redux/reducers/settings/reducer';
import InputTotp from 'ui/Formik/InputTotp';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import HeaderActions from 'layouts-elements/HeaderActions';
import { notificationsInfoFields } from '../../services/utils/ipuntFields/ipuntFields';
import WalletSideBar from '../Wallets/WalletSideBar/WalletSideBar';

const SecuritySettings = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [status, setTwoFaStatus] = useState(false);
	const [totpForm, setTotpForm] = useState(false);

	useEffect(() => {
		dispatch(getTwoFaStatusRequest({ setTwoFaStatus }));
	}, [dispatch]);

	const twoFaButtonHandler = () => {
		if (status) {
			setTotpForm(true);
		} else {
			history.push('/security-settings/2fa');
		}
	};

	const initialValue = {
		totp: '',
	};

	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required(notificationsInfoFields.totp.required)
			.length(6, notificationsInfoFields.totp.length)
			.matches(/\d/, notificationsInfoFields.totp.matches),
	});

	return (
		<>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-content"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<HeaderActions />
						<div className="wallet-content-header wallet-content-header">
							<div className="wallet-content-header__title">
								{totpForm ? (
									<div className="security-content__header">
										{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
										<button
											type="button"
											className="btn btn--icon btn--back"
											onClick={() => setTotpForm(false)}
										/>
										<p>2FA Settings</p>
									</div>
								) : (
									<div className="security-content__header">
										<p>Security</p>
									</div>
								)}
							</div>
						</div>

						<div
							className={totpForm ? 'security-content security-content--w-660' : 'security-content'}
						>
							{/* {!totpForm ? (
								<div className="setting-content-title">
									<p>Security Settings</p>
								</div>
							) : (
								<div className="setting-content-title">
									<button type="button" className="setting-content-back" onClick={handleBackClick}>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
												stroke="#173B58"
												strokeWidth="1.5"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M20.4999 12H3.66992"
												stroke="#173B58"
												strokeWidth="1.5"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
									<p>2FA Settings</p>
								</div>
							)} */}

							{!totpForm && (
								<>
									<div className="security-content__title">
										<p>Two-Factor Authentication</p>
									</div>
									{status && (
										<div className="security-content__input green-bg">
											<input value="Enabled" disabled />
										</div>
									)}
								</>
							)}
							{totpForm ? (
								<Formik
									initialValues={initialValue}
									validationSchema={validationSchema}
									onSubmit={(values, { setSubmitting }) => {
										dispatch(
											disableTwoFaRequest({
												totp: values.totp,
												setTotpForm,
												setTwoFaStatus,
											}),
										);
										setSubmitting(false);
									}}
									validateOnBlur
									enableReinitialize
								>
									<>
										<div className="security-content__body">
											<div className="security-content__body-item">
												<Form className="form">
													<div className="security-content__title">
														<p>Disable 2FA</p>
													</div>
													<div className="enable-2fa-label">
														<p>2FA Code from Authenticator App</p>
													</div>
													<Field
														type="text"
														placeholder="Please enter the 6-digit code"
														name="totp"
														required
														component={InputTotp}
														className="mb-0"
													/>
													<div className="mt-24">
														<button
															className="btn btn-primary btn--full"
															aria-label="form-submit"
															type="submit"
														>
															Disable 2FA
														</button>
													</div>
												</Form>
											</div>
										</div>
									</>
								</Formik>
							) : (
								<>
									<div className="security-content__enable-2fa-text">
										<p>
											For the security of your account we strongly recommend enabling 2FA. Once
											enabled, you will need to enter a one-time 6-digit code each time you login
											into your account, withdraw funds or change your password.
										</p>
									</div>
									<button
										onClick={twoFaButtonHandler}
										type="button"
										className={`btn btn--small btn--w-100 ${
											status ? 'btn-outline' : 'btn-primary'
										}`}
									>
										{status ? 'Disable 2FA' : 'Enable 2FA'}
									</button>
								</>
							)}
						</div>

						{/* {!totpForm && (
							<div className="setting-content">
								<div className="setting-content__title">
									<p>Change password</p>
								</div>
								<div className="enable-2fa-text">
									<p>
										For the security of your account we strongly recommend enabling 2FA. Once
										enabled, you will need to enter a one-time 6-digit code each time you login into
										your account, withdraw funds or change your password.
									</p>
								</div>
								<button type="button" className="btn btn--mw-180 btn-primary">
									Change password
								</button>
							</div>
						)} */}
					</motion.div>
				</div>
			</section>
		</>
	);
};

export default SecuritySettings;
