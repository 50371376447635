import { FC } from 'react';
import Auth from 'layouts/Auth';
import Verification from 'components/Verification/Verification';

// ==========================================:
const VerificationPage: FC = () => {
	return (
		<Auth title="Verification">
			<Verification />
		</Auth>
	);
};
export default VerificationPage;
