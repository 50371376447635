/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IRequestPayloadData,
	IRequestPayloadTwoFaData,
	IConfirmationStore,
	ITwoFaResponse,
	IVerificationFilesRequestPayloadData,
	ITwoFaPayloadData,
} from './types';

// ==========================================:
export const initialState: IConfirmationStore = {
	token: null,
	twoFa: null,
	confirmationPasswordLoader: false,
	confirmationTwoFaLoader: false,
	confirmationTwoCodeFaLoader: false,
	confirmationEmailSuccess: false,
	confirmationDocumentsLoader: false,
	confirmationDocumentsSuccess: false,
	confirmationGenerateTwoFaLoader: false,
	confirmationGenerateTwoFa: null,
};
// ==========================================:
const confirmation = createSlice({
	name: '@@confirmation',
	initialState,
	reducers: {
		confirmationPasswordRequest: (state, action: PayloadAction<IRequestPayloadData>) => {
			const { payload } = action;
			const stateData = state;
			stateData.token = payload?.token;
			stateData.confirmationPasswordLoader = true;
		},
		confirmationPasswordSuccess: (state) => {
			const stateData = state;
			stateData.confirmationPasswordLoader = false;
		},
		confirmationTwoFaRequest: (state, action: PayloadAction<IRequestPayloadData>) => {
			const stateData = state;
			stateData.confirmationTwoFaLoader = true;
		},
		confirmationTwoFaSuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateData = state;
			stateData.twoFa = payload;
			stateData.confirmationTwoFaLoader = false;
		},
		confirmationTwoFaCodeRequest: (state, action: PayloadAction<IRequestPayloadTwoFaData>) => {
			const stateData = state;
			stateData.confirmationTwoCodeFaLoader = true;
		},
		confirmationTwoFaCodeSuccess: (state) => {
			const stateData = state;
			stateData.confirmationTwoCodeFaLoader = false;
		},
		confirmationEmailRequest: (state, action: PayloadAction<IRequestPayloadData>) => {
			const stateData = state;
			stateData.confirmationEmailSuccess = false;
		},
		confirmationEmailSuccess: (state) => {
			const stateData = state;
			stateData.confirmationEmailSuccess = true;
		},
		confirmationDocumentsRequest: (
			state,
			action: PayloadAction<IVerificationFilesRequestPayloadData>,
		) => {
			const stateData = state;
			stateData.confirmationDocumentsLoader = false;
		},
		confirmationDocumentsSuccess: (state) => {
			const stateData = state;
			stateData.confirmationDocumentsLoader = true;
			stateData.confirmationDocumentsSuccess = true;
		},
		confirmationGenerateTwoFaRequest: (state, action: PayloadAction<ITwoFaPayloadData>) => {
			const stateData = state;
			stateData.confirmationGenerateTwoFaLoader = false;
		},
		confirmationGenerateTwoFaSuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateData = state;
			stateData.confirmationGenerateTwoFaLoader = true;
			stateData.confirmationGenerateTwoFa = payload;
		},
		confirmationSendCodeRequest: (state, action: PayloadAction<IRequestPayloadTwoFaData>) => {
			const stateData = state;
			stateData.confirmationGenerateTwoFaLoader = false;
		},
		confirmationSendCodeSuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateData = state;
			stateData.confirmationGenerateTwoFaLoader = true;
			stateData.confirmationGenerateTwoFa = payload;
		},
		confirmationInitState: () => initialState,
	},
});
export default confirmation.reducer;
export const {
	confirmationPasswordRequest,
	confirmationPasswordSuccess,
	confirmationTwoFaRequest,
	confirmationTwoFaSuccess,
	confirmationTwoFaCodeRequest,
	confirmationTwoFaCodeSuccess,
	confirmationEmailRequest,
	confirmationEmailSuccess,
	confirmationInitState,
	confirmationDocumentsRequest,
	confirmationDocumentsSuccess,
	confirmationGenerateTwoFaRequest,
	confirmationGenerateTwoFaSuccess,
	confirmationSendCodeRequest,
	confirmationSendCodeSuccess,
} = confirmation.actions;
