import { store } from 'redux/store';
import { getAuth } from 'redux/reducers/auth/selectors';
import { refreshTokenRequest } from 'redux/reducers/auth/reducer';

const getFormatTime = (plusMinutes = 0) => {
	const date = new Date();
	const UTCMonth = date.getUTCMonth() + 1;
	const UTCMinutes = date.getUTCMinutes() + plusMinutes;
	return `${date.getUTCFullYear()}-${UTCMonth}-${date.getUTCDate()} ${date.getUTCHours()}:${UTCMinutes}:${date.getUTCSeconds()}`;
};

export const getTokenRefreshTime = () => {
	return getFormatTime(50);
};

const matchFormatTime = () => {
	const state = store.getState();
	const auth = getAuth(state);

	if (auth && auth.tokenRefreshTime) {
		const date = auth.tokenRefreshTime;
		return auth.accessToken && new Date(getFormatTime()) > new Date(date) && !auth.tokenUpdating;
	}

	return false;
};

export const checkAndRefreshToken = () => {
	matchFormatTime() && store.dispatch(refreshTokenRequest());
};
