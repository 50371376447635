import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import {
	depositAddressRequest,
	depositAddressSuccess,
	depositsInitState,
	feeRequest,
	feeSuccess,
	feeValueRequest,
	feeValueSuccess,
	spreadFeeRequest,
	spreadFeeSuccess,
} from './reducer';
import {
	IDepositAddressRequest,
	IDepositAddressResponse,
	ISpreadFeeRequest,
	ISpreadFeeResponse,
} from './types';

// =============================================================:
function* depositAddressRequestWorker(action: PayloadAction<IDepositAddressRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IDepositAddressResponse = yield call(api.deposits.getDepositAddress, payload);

		yield put(depositAddressSuccess(response));
	} catch (error) {
		yield put(depositsInitState);
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* feeRequestWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.deposits.getFee, payload);
		yield put(feeSuccess(Number(response.toFixed(6))));
	} catch (error) {
		yield put(depositsInitState);
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* feeValueRequestWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.deposits.getFeeValue, payload);
		yield put(feeValueSuccess(response));
	} catch (error) {
		yield put(depositsInitState);
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* spreadFeeRequestWorker(action: PayloadAction<ISpreadFeeRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ISpreadFeeResponse = yield call(api.deposits.getSpreadFee, payload);
		yield put(spreadFeeSuccess(response));
	} catch (error) {
		yield put(depositsInitState);
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* depositsSaga() {
	yield takeEvery(depositAddressRequest.type, depositAddressRequestWorker);
	yield takeEvery(feeRequest.type, feeRequestWorker);
	yield takeEvery(feeValueRequest.type, feeValueRequestWorker);
	yield takeEvery(spreadFeeRequest.type, spreadFeeRequestWorker);
}
