// ==========================================:
import React from 'react';
import logo from 'assets/dist/img/logo-footer.svg';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { navList } from 'routes/routesList';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Footer = () => {
	const dispatch = useDispatch();
	return (
		<footer className="footer">
			<div className="footer-container">
				<a href="mailto:support@kaiserex.com" className="footer__support">
					support@kaiserex.com
				</a>
				<div className="footer-nav">
					<ul>
						<li>
							<a href={navList.termsOfUse.path} target="_blank" rel="noreferrer">
								Terms & Conditions
							</a>
						</li>
						<li>
							<a href={navList.privacyPolicy.path} target="_blank" rel="noreferrer">
								Privacy Policy
							</a>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
