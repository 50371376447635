export const getPriceLevel = (
	sellLayers: any,
	buyLayers: any,
	count: number,
	reversed: boolean,
	type?: string,
) => {
	let selectedLevel = null;
	let selectedLevelFinal = null;
	if (type && type === 'sell') {
		// eslint-disable-next-line no-plusplus
		for (let level = 1; level <= Object.keys(sellLayers).length; level++) {
			const priceLevel = parseFloat(Object.keys(sellLayers)[level - 1]);
			if (reversed) {
				if (count <= priceLevel) {
					selectedLevel = priceLevel;
					break; // Exit the loop once a level is found
				}
			} else {
				const calcSell = count / buyLayers[priceLevel];
				if (calcSell <= priceLevel) {
					selectedLevel = priceLevel;
					break; // Exit the loop once a level is found
				}
			}
		}
	} else {
		// eslint-disable-next-line no-plusplus
		for (let level = 1; level <= Object.keys(sellLayers).length; level++) {
			const priceLevel = parseFloat(Object.keys(sellLayers)[level - 1]);
			if (reversed) {
				const calcSell = count / buyLayers[priceLevel];
				if (calcSell <= priceLevel) {
					selectedLevel = priceLevel;
					break; // Exit the loop once a level is found
				}
			} else if (count <= priceLevel) {
				selectedLevel = priceLevel;
				break; // Exit the loop once a level is found
			}
		}
	}
	if (selectedLevel !== null) {
		selectedLevelFinal = String(selectedLevel);
	} else {
		selectedLevelFinal = Object.keys(sellLayers)[Object.keys(sellLayers).length - 1];
	}
	return selectedLevelFinal;
};
