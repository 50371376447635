import Dashboard from 'layouts/Dashboard';
import { Helmet } from 'react-helmet';
import LoginInput from './LoginInput';
import Logo from '../../assets/img/top_logo.svg';

const UserLogin = () => {
	return (
		<>
			<Helmet>
				<body className="login-page" />
			</Helmet>
			<Dashboard>
				<section className="login-section">
					<a href="/" className="login-section__logo">
						<img src={Logo} alt="" />
					</a>
					<div className="login">
						<div className="login-form">
							<LoginInput />
						</div>
					</div>
				</section>
			</Dashboard>
		</>
	);
};

export default UserLogin;
