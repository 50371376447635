/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITwoFaRequest, ITwoFaResponse, ITwoFaUpdateRequest, ITwoFoStore } from './types';

// ==========================================:
export const initialState: ITwoFoStore = {
	twoFaDate: null,
	twoFaKeyLoading: false,
};

// ==========================================:
const twoFa = createSlice({
	name: '@@twoFa',
	initialState,
	reducers: {
		twoRequest: (state) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaKeyLoading = true;
		},
		twoSuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateTwoFa = state;
			stateTwoFa.twoFaDate = payload;
		},
		twoCodeRequest: (state, action: PayloadAction<ITwoFaRequest>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaKeyLoading = true;
		},
		twoCodeUpdateRequest: (state, action: PayloadAction<ITwoFaUpdateRequest>) => {},
		twoCodeSuccess: () => {},
		twoFoInitState: () => initialState,
	},
});
export default twoFa.reducer;
export const { twoRequest, twoCodeRequest, twoSuccess, twoFoInitState, twoCodeUpdateRequest } =
	twoFa.actions;
