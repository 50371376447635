import React, { FC } from 'react';
import IconSvg from 'ui/Svg/IconSvg';

const ProgressBar: FC<{ progress: number }> = ({ progress }) => (
	<div className="progress-wrapper">
		<span className="verification-upload__button-content">
			<IconSvg name="upload" />
			<span>Uploading...</span>
		</span>

		<div className="progress-bar-wrapper">
			<span className="progress-text">{progress}%</span>
			<div className="progress-bar-container">
				<div className="progress-bar" style={{ width: `${progress}%` }} />
			</div>
		</div>
		<p>We are already uploading your file. Please, wait...</p>
	</div>
);

export default ProgressBar;
