import React, { FC, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import Select from '../index';
import { ICurrencySelect, ICurrencySelectCustomList, ICurrencySelectItem } from './types';

const CurrencySelectCustomInput: FC<ICurrencySelectItem> = ({ code, name, img_path }) => (
	<span className="coin coin--type7" style={{ display: name && code ? '' : 'none' }}>
		{img_path ? (
			<>
				<span className="coin__icon">
					<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={name} />
				</span>
				<span className="coin__text-wrap">
					<span className="coin__text upper__text">
						<span className="coin__text-more coin__text-bold">{name}</span>
						{code}
					</span>
				</span>
			</>
		) : (
			<span className="coin__text upper__text">
				<span className="coin__text-more">{name}</span>
				{code}
			</span>
		)}
	</span>
);

const CurrencySelectCustomList: FC<ICurrencySelectCustomList> = ({
	arr,
	onChange,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	activeTabVal,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	tabs,
}) => (
	<>
		{tabs ? (
			<>
				{arr && activeTabVal === 'fiat' && (
					<motion.ul
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.5 } }}
						exit={{ opacity: 0, transition: { duration: 0.5 } }}
					>
						{arr
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							.filter(({ type }) => type === 'fiat')
							.map((el) => (
								<li key={el.code}>
									<button type="button" onClick={() => onChange(el)}>
										<span className="coin coin--type7">
											{el.img_path && (
												<span className="coin__icon">
													<img src={`/img/currencies/${el.code.toLowerCase()}.svg`} alt={el.name} />
												</span>
											)}
											<span className="coin__text-wrap">
												<span className="coin__text upper__text">
													<span className="coin__text-more">{el.name}</span>
													{el.code}
												</span>
											</span>
										</span>
									</button>
								</li>
							))}
					</motion.ul>
				)}
				{arr && activeTabVal === 'crypto' && (
					<motion.ul
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.5 } }}
						exit={{ opacity: 0, transition: { duration: 0.5 } }}
					>
						{arr
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							.filter(({ type }) => type !== 'fiat')
							.map((el) => (
								<li key={el.code}>
									<button type="button" onClick={() => onChange(el)}>
										<span className="coin coin--type7">
											{el.img_path && (
												<span className="coin__icon">
													<img src={`/img/currencies/${el.code.toLowerCase()}.svg`} alt={el.name} />
												</span>
											)}
											<span className="coin__text-wrap">
												<span className="coin__text upper__text">
													<span className="coin__text-more">{el.name}</span>
													{el.code}
												</span>
											</span>
										</span>
									</button>
								</li>
							))}
					</motion.ul>
				)}
			</>
		) : (
			<ul>
				{arr &&
					arr.map((el) => (
						<li key={el.code}>
							<button type="button" onClick={() => onChange(el)}>
								<span className="coin coin--type7">
									{el.img_path && (
										<span className="coin__icon">
											<img src={`/img/currencies/${el.code.toLowerCase()}.svg`} alt={el.name} />
										</span>
									)}
									<span className="coin__text-wrap">
										<span className="coin__text upper__text">
											<span className="coin__text-more">{el.name}</span>
											{el.code}
										</span>
									</span>
								</span>
							</button>
						</li>
					))}
			</ul>
		)}
	</>
);

const CurrencySelect: FC<ICurrencySelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	activeValue,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	tabs,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			id: 0,
			code: '',
			name: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] = useState<ICurrencySelectItem>(initActiveCurrency);
	const [searchCurrency, setSearchCurrency] = useState<ICurrencySelectItem[]>([]);
	const [activeTabVal, setActiveTabVal] = useState('fiat');

	useEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	// console.log(activeTabVal);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	useEffect(() => {
		setActiveCurrency(activeValue || initActiveCurrency);
		activeValue?.type === 'token'
			? setActiveTabVal('crypto')
			: setActiveTabVal(activeValue?.type || 'fiat');
	}, [activeValue, initActiveCurrency]);

	const onChangeHandler = (el: ICurrencySelectItem) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};
	const customList = {
		list: (
			<CurrencySelectCustomList
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				activeTabVal={activeTabVal}
				arr={searchCurrency}
				onChange={onChangeHandler}
				tabs={tabs}
			/>
		),
		activeElement: activeCurrency.code,
	};

	useEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);

	const activeTabHandler = (value: any) => {
		setActiveTabVal(value);
	};

	return (
		<Select
			{...props}
			customInput={<CurrencySelectCustomInput {...activeCurrency} />}
			customList={customList}
			searchField
			setSearchCurrency={setSearchCurrency}
			arr={arr}
			tabs={tabs}
			activeTab={activeTabHandler}
			activeTabVal={activeTabVal}
		/>
	);
};

export default CurrencySelect;
