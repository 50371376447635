import React, { useEffect } from 'react';
import two1 from 'assets/dist/img/2fa-app1.png';
import two2 from 'assets/dist/img/2fa-app2.png';
import { useDispatch, useSelector } from 'react-redux';
import { twoCodeRequest, twoRequest } from 'redux/reducers/twoFa/reducer';
import { getTwoFaData } from 'redux/reducers/twoFa/selectors';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import InputTotp from 'ui/Formik/InputTotp';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import WalletSideBar from '../Wallets/WalletSideBar/WalletSideBar';

const TwoFaSettings = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const twoFa = useSelector(getTwoFaData);
	useEffect(() => {
		dispatch(twoRequest());
	}, [dispatch]);
	const validationSchema = yup.object().shape({
		digit_code: yup
			.string()
			.required(notificationsInfoFields.digit_code.required)
			.matches(/\d/, notificationsInfoFields.digit_code.matches)
			.length(6, notificationsInfoFields.digit_code.length),
	});
	return (
		<>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-content"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<Formik
							initialValues={{ digit_code: '' }}
							validationSchema={validationSchema}
							onSubmit={(values, { setSubmitting }) => {
								dispatch(
									twoCodeRequest({
										google2fa_enabled: true,
										totp: String(values.digit_code),
										history,
									}),
								);
								setSubmitting(false);
							}}
						>
							{({ isSubmitting, errors }) => (
								<Form className="form">
									<div className="security-content--w-660">
										<div className="security-content__header">
											<Link to="/security-settings" className="btn btn--icon btn--back" />
											<p>Security</p>
										</div>
										<div className="security-content__body-2fa">
											<div className="security-content__title">
												<p>2FA Settings</p>
											</div>
											<div className="security-content__body-item ">
												<div className="settings-2fa">
													<div className="settings-2fa__title">
														<div className="settings-2fa__title-number">
															<p>1</p>
														</div>
														<p>Download 2FA App</p>
													</div>
													<div className="settings-2fa__text">
														<p>
															Please download and install an authentication application (we
															recommend Google Authenticator or Authy) on your mobile device. This
															app will generate a one-time 6-digit access code which is required to
															access your account.
														</p>
													</div>
													<div className="settings-2fa-app">
														<a
															href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
															target="_blank"
															className="settings-2fa-app__item"
															rel="noreferrer"
														>
															<img src={two1} alt="" />
														</a>
														<a
															href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
															target="_blank"
															className="settings-2fa-app__item"
															rel="noreferrer"
														>
															<img src={two2} alt="" />
														</a>
													</div>
													<div className="settings-2fa__divider" />
													<div className="settings-2fa__title">
														<div className="settings-2fa__title-number">
															<p>2</p>
														</div>
														<p>Save 2FA Backup Key and Scan QR Code</p>
													</div>
													<div className="settings-2fa__text">
														<p>
															Please backup your 2FA recovery key by saving to offline storage,
															write down on a piece of paper or print a copy and store it safety. If
															your mobile device gets lost, stolen or erased you will need this key
															to recover your 2FA access.
														</p>
													</div>
													<div className="settings-2fa-qr-code">
														<div className="settings-2fa-qr-code__photo">
															<img src={twoFa?.QR_Image} alt="" />
														</div>
														<div className="settings-2fa-qr-code__info">
															<div className="input">
																<div className="input__name">
																	<p>Backup / Secret Key</p>
																</div>
																<div className="input-wrapper">
																	<input
																		className="input-item"
																		type="text"
																		placeholder="Enter Key"
																		readOnly
																		value={twoFa?.secret === undefined ? '' : twoFa?.secret}
																	/>
																	<div className="input__icon input__icon--eye" />
																</div>
																<div className="input__notification input__notification--type3">
																	<p>
																		Please make sure you have made a backup of your recovery key.
																	</p>
																</div>
															</div>
														</div>
													</div>
													<div className="settings-2fa__divider" />
													<div className="settings-2fa__title">
														<div className="settings-2fa__title-number">
															<p>3</p>
														</div>
														<p>Confirm 2FA Activation</p>
													</div>
													<div className="settings-2fa-item">
														<div className="input">
															<div className="input-wrapper">
																<Field
																	title="2FA Code from Authenticator App"
																	type="text"
																	placeholder="Please enter the 6-digit code"
																	name="digit_code"
																	required
																	component={InputTotp}
																/>
															</div>
														</div>
													</div>
													<div className="settings-2fa-item mt-24">
														<button
															disabled={isSubmitting}
															className="btn btn-primary btn--full"
															aria-label="form-submit"
															type="submit"
														>
															Enable 2FA
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</motion.div>
				</div>
			</section>
		</>
	);
};
export default TwoFaSettings;
