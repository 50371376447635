import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { routesList } from 'routes/routesList';
import LocaleStorage from 'services/HOC/LocaleStorage/index';
import { checkAndRefreshToken } from 'services/utils/tokenRefresh';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { roundingNumber } from 'services/utils/roundingNumber';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { getUserData } from '../../redux/reducers/auth/selectors';
// ==========================================:

const App: FC = () => {
	const history = useHistory();
	const location = useLocation();
	const userData = useSelector(getUserData);
	useEffect(() => {
		if (
			location.pathname !== '/verification' &&
			location.pathname !== '/terms' &&
			location.pathname !== '/privacy' &&
			(userData?.status?.name === 'unverified' || userData?.status?.name === 'pending')
		) {
			history.push('/verification');
		}
	}, [userData?.status?.name, history, location.pathname]);

	useEffect(() => {
		checkAndRefreshToken();
	}, [location.pathname]);
	return (
		<LocaleStorage>
			<AnimatePresence exitBeforeEnter>
				<Switch location={location} key={location.pathname}>
					{routesList?.map(({ path, component, isNotExact }) => (
						<Route key={path} exact={!isNotExact} path={path} component={component} />
					))}
					<Redirect to="/" />
				</Switch>
			</AnimatePresence>
			{/* <Switch key={location.pathname}>
				{routesList?.map(({ path, component, isNotExact }) => (
					<Route key={path} exact={!isNotExact} path={path} component={component} />
				))}
				<Redirect to="/" />
			</Switch> */}
		</LocaleStorage>
	);
};

export default App;
