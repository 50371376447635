import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserData } from 'redux/reducers/auth/selectors';
import { navList } from 'routes/routesList';

const HeaderActions = () => {
	const userData = useSelector(getUserData);
	return (
		<div className="sidebar-nav__right">
			{userData && (
				<>
					<Link
						to={navList.depositFiat.path}
						className="btn btn-outline btn-outline--deposit btn-outline--sm"
					>
						Deposit
					</Link>
					<Link
						to={navList.withdrawFiat.path}
						className="btn btn-outline btn-outline--withdrawal btn-outline--sm"
					>
						Withdrawal
					</Link>
				</>
			)}
		</div>
	);
};

export default HeaderActions;
