import { createSlice } from '@reduxjs/toolkit';

export interface ISidebarStore {
	minimizedSidebar: boolean;
}

export const initialState: ISidebarStore = {
	minimizedSidebar: false,
};

const sidebar = createSlice({
	name: '@@sidebar',
	initialState,
	reducers: {
		toggleMinimized: (state) => {
			const newState = state;
			newState.minimizedSidebar = !state.minimizedSidebar;
		},
		sidebarInitState: () => initialState,
	},
});
export default sidebar.reducer;
export const { toggleMinimized, sidebarInitState } = sidebar.actions;
