import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IWalletAddressApi } from './types';
// ==========================================:
export const walletAddresses: IWalletAddressApi = {
	getWalletAddresses: (payload) =>
		http
			.get(endpoint.walletAddresses.WALLET_ADDRESSES, { params: payload })
			.then(({ data }) => data),
	deleteWalletAddress: (id) =>
		http.delete(endpoint.walletAddresses.WALLET_ADDRESS(id)).then(({ data }) => data),
	createWalletAddress: (payload) =>
		http.post(endpoint.walletAddresses.WALLET_ADDRESSES, payload).then(({ data }) => data),
	updateWalletAddress: (payload) =>
		http.put(endpoint.walletAddresses.WALLET_ADDRESS(payload.id), payload).then(({ data }) => data),
};
