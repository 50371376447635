import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ISidebarStore } from './reducer';

const getSidebarState = (state: IStoreState): ISidebarStore => state.sidebar;

export const getMinimizedSidebarState = createSelector(
	[getSidebarState],
	(sidebarStates: ISidebarStore): boolean => sidebarStates.minimizedSidebar,
);
