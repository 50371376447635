import { call, put, takeEvery } from 'redux-saga/effects';
import { ICurrencyDataItem, IFeesData, ILimitItem } from 'redux/reducers/currency/types';
import { api } from 'services';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import {
	currencyDataRequest,
	currencyDataSuccess,
	feeDataRequest,
	feeDataSuccess,
	limitsDataRequest,
	limitsDataSuccess,
} from './reducer';

// =============================================================:

// =============================================================:

function* currencyDataRequestWorker() {
	try {
		yield put(showLoading());
		const response: ICurrencyDataItem[] = yield call(api.currency.getCurrency);
		yield put(currencyDataSuccess(response));
	} catch (error) {
		// console.log(error);
	} finally {
		yield put(hideLoading());
	}
}

function* feesDataRequestWorker() {
	try {
		const response: IFeesData = yield call(api.currency.getFees);
		yield put(feeDataSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error')
	}
}

function* limitsDataRequestWorker() {
	try {
		const response: ILimitItem[] = yield call(api.currency.getLimits);
		yield put(limitsDataSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error')
	}
}
// =============================================================:
export function* currencySaga() {
	yield takeEvery(currencyDataRequest.type, currencyDataRequestWorker);
	yield takeEvery(feeDataRequest.type, feesDataRequestWorker);
	yield takeEvery(limitsDataRequest.type, limitsDataRequestWorker);
}
