import React, { FC, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconSvg from '../Svg/IconSvg';

interface IPerPageSelect {
	onChange: (value: number | undefined) => void;
}

const PerPageOptions = ['10', '20', '50', '100', 'All'];

const PerPageSelect: FC<IPerPageSelect> = ({ onChange }) => {
	const [opened, setOpened] = useState(false);
	const [selectValue, setSelectValue] = useState(PerPageOptions[0]);

	const dropdownElement = useRef(null);
	useClickOutside(dropdownElement, () => setOpened(false));

	const handleSelect = (value: string) => {
		setOpened(false);
		setSelectValue(value);
		if (value === 'All') {
			onChange(undefined);
		} else {
			onChange(Number(value));
		}
	};

	const toggleSelect = () => {
		setOpened(!opened);
	};

	return (
		<div className="select--per-page-wrapper">
			<div className={`select select--type1 ${opened ? 'active' : ''}`}>
				<button type="button" className="select__current" onClick={toggleSelect}>
					{selectValue}
					<span className="select__current-arrow">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M8.32014 10.5733C8.16014 10.7867 7.84014 10.7867 7.68014 10.5733L5.48014 7.64C5.28237 7.37631 5.47052 7 5.80014 7L10.2001 7C10.5298 7 10.7179 7.37631 10.5201 7.64L8.32014 10.5733Z"
								fill="#626A85"
							/>
						</svg>
					</span>
				</button>
				<AnimatePresence>
					{opened && (
						<motion.div
							ref={dropdownElement}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.3 } }}
							exit={{ opacity: 0, transition: { duration: 0.3 } }}
						>
							<div className="select__drop">
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul>
											{PerPageOptions.map((el) => (
												<li key={el}>
													<button type="button" onClick={() => handleSelect(el)}>
														{el}
													</button>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			Rows Per Page
		</div>
	);
};

export default PerPageSelect;
