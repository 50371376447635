import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import L from 'i18n-react';
import { confirmationPasswordRequest } from 'redux/reducers/confirmation/reducer';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';
import Input from '../../../ui/Formik/Input';
import { IRegisterSubmitValue } from './types';
import { popUpOpen } from '../../../redux/reducers/popUp/reducer';
import loginBannerImage from '../../../assets/dist/img/login-banner@2x-min.png';

const CreatePassword = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const registerPasswordSubmit = (obg: IRegisterSubmitValue) => {
		const { password, confirmPassword } = obg;
		if (password === confirmPassword) {
			const url = window.location.toString();
			const token = url.split('token=').pop()?.split('&')[0];
			localStorage.confirmationToken = token;
			dispatch(confirmationPasswordRequest({ token, password, history }));
		}
	};
	const initialValues = {
		password: '',
		confirmPassword: '',
	};
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(notificationsInfoFields.passwordValidation.required)
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				notificationsInfoFields.passwordValidation.matches,
			)
			.max(25, 'Password is too long. Maximum 25 characters.'),
		confirmPassword: yup
			.string()
			.required(notificationsInfoFields.passwordValidation.required)
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				notificationsInfoFields.passwordValidation.matches,
			)
			.oneOf([yup.ref('password'), null], notificationsInfoFields.passwordValidation.passwordsMatch)
			.max(25, 'Password is too long. Maximum 25 characters.'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};
	return (
		<>
			<section className="login-section">
				<div className="container">
					<div className="login">
						<div className="create-account-progress">
							<div className="create-account-progress__step active">
								<div className="create-account-progress__step-text">
									<p>Create Password</p>
								</div>
								<div className="create-account-progress__step-number">1</div>
							</div>
							<div className="create-account-progress__line" />
							<div className="create-account-progress__step">
								<div className="create-account-progress__step-text">
									<p>2FA Setup</p>
								</div>
								<div className="create-account-progress__step-number">2</div>
							</div>
						</div>

						<div className="login-form">
							<div className="login-form__title">
								<p>Create Password</p>
								<p className="login-form__subtitle">Please enter a strong and unique password.</p>
							</div>

							<>
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={(values: IRegisterSubmitValue, { resetForm, setSubmitting }) => {
										registerPasswordSubmit(values);
										setSubmitting(false);
										resetForm();
									}}
									validateOnBlur
									enableReinitialize
								>
									{({ isValid, dirty }) => (
										<Form className="form">
											<div className="login-form-item">
												<div className="input">
													<p className="input__name">Password</p>
													<div className="input-wrapper">
														<Field
															type={isShowPass ? 'text' : 'password'}
															placeholder="Enter Your Password"
															name="password"
															required
															component={Input}
															ariaLabel="show-password"
															showPass={isShowPass}
															setIsShowPass={handlePassDisplay}
														/>

														<button
															onClick={() => {
																setIsShowPass(!isShowPass);
															}}
															type="button"
															className="show-pass"
														>
															<svg
																style={{ display: isShowPass ? 'none' : 'block' }}
																className="text-type"
																width="22"
																height="22"
																viewBox="0 0 22 22"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M13.3196 8.68125L8.68125 13.3196C8.08542 12.7237 7.71875 11.9079 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C11.9079 7.71875 12.7237 8.08542 13.3196 8.68125Z"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M16.3358 5.29187C14.7317 4.08187 12.8983 3.42188 11.0008 3.42188C7.765 3.42188 4.74917 5.32854 2.65 8.62854C1.825 9.92104 1.825 12.0935 2.65 13.386C3.37417 14.5227 4.2175 15.5035 5.13417 16.2919"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.71875 17.899C8.76375 18.339 9.87292 18.5773 11.0004 18.5773C14.2362 18.5773 17.2521 16.6706 19.3512 13.3706C20.1762 12.0781 20.1762 9.90562 19.3512 8.61312C19.0487 8.13646 18.7187 7.68729 18.3796 7.26562"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M14.2178 11.6406C13.9795 12.9331 12.9253 13.9873 11.6328 14.2256"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8.67953 13.3203L1.83203 20.1678"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M20.1678 1.83594L13.3203 8.68344"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>

															<svg
																style={{ display: isShowPass ? 'block' : 'none' }}
																className="password-type"
																width="22"
																height="22"
																viewBox="0 0 22 22"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M14.2821 11.0004C14.2821 12.8154 12.8154 14.2821 11.0004 14.2821C9.18542 14.2821 7.71875 12.8154 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C12.8154 7.71875 14.2821 9.18542 14.2821 11.0004Z"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M11.0008 18.5849C14.2367 18.5849 17.2525 16.6782 19.3517 13.3782C20.1767 12.0857 20.1767 9.91323 19.3517 8.62073C17.2525 5.32073 14.2367 3.41406 11.0008 3.41406C7.765 3.41406 4.74917 5.32073 2.65 8.62073C1.825 9.91323 1.825 12.0857 2.65 13.3782C4.74917 16.6782 7.765 18.5849 11.0008 18.5849Z"
																	stroke="#1D1F21"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</button>
													</div>
												</div>
											</div>
											<div className="login-form-item mb-30">
												<div className="input">
													<p className="input__name">Confirm Password</p>
													<div className="input-wrapper">
														<Field
															type={isShowConfirmPass ? 'text' : 'password'}
															placeholder="Confirm password"
															name="confirmPassword"
															required
															component={Input}
															ariaLabel="show-confirm-password"
															showPass={isShowConfirmPass}
															setIsShowConfirmPass={handleConfirmPassDisplay}
														/>

														<button
															onClick={() => {
																setIsShowConfirmPass(!isShowConfirmPass);
															}}
															type="button"
															className="show-pass"
														>
															<svg
																style={{ display: isShowConfirmPass ? 'none' : 'block' }}
																className="text-type"
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M9.47 14.53L2 22"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M22 2L14.53 9.47"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<svg
																style={{ display: isShowConfirmPass ? 'block' : 'none' }}
																className="password-type"
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
																	stroke="#777E90"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</button>
													</div>
												</div>
											</div>
											<div className="login-form-item">
												<button
													disabled={!isValid || !dirty}
													aria-label="form-submit"
													type="submit"
													className="btn btn-primary btn--full"
												>
													Confirm
												</button>
											</div>
										</Form>
									)}
								</Formik>
							</>
						</div>
					</div>

					<div className="login-banner" id="login_banner">
						<img src={loginBannerImage} alt="" />
					</div>
				</div>
			</section>

			{/* <section className="create-account-section"> */}
			{/*	<div className="container"> */}
			{/*	<div className="authorization-form"> */}
			{/*	<h2 className="form-title">Create Password</h2> */}
			{/*	<p className="form-subtitle">Welcome! Please, create password for your account</p> */}
			{/*	<div className="form-body"> */}
			{/*		<div className="authoriztion-item"> */}
			{/*			<div className="input input--mb-0"> */}
			{/*				<p className="input__name">Password</p> */}
			{/*				<div className="input-wrapper"> */}
			{/*					<input className="input-item " type="text" placeholder="Enter Your Password" /> */}
			{/*					<button type="button" className="show-pass"> */}
			{/*						<svg */}
			{/*							className="text-type" */}
			{/*							width="24" */}
			{/*							height="24" */}
			{/*							viewBox="0 0 24 24" */}
			{/*							fill="none" */}
			{/*							xmlns="http://www.w3.org/2000/svg" */}
			{/*						> */}
			{/*							<path */}
			{/*								d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M9.47 14.53L2 22" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M22 2L14.53 9.47" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*						</svg> */}
			{/*						<svg */}
			{/*							className="password-type" */}
			{/*							width="24" */}
			{/*							height="24" */}
			{/*							viewBox="0 0 24 24" */}
			{/*							fill="none" */}
			{/*							xmlns="http://www.w3.org/2000/svg" */}
			{/*						> */}
			{/*							<path */}
			{/*								d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*						</svg> */}
			{/*					</button> */}
			{/*				</div> */}
			{/*			</div> */}
			{/*		</div> */}
			{/*		<div className="authoriztion-item"> */}
			{/*			<div className="input input--mb-0"> */}
			{/*				<p className="input__name">Confirm Password</p> */}
			{/*				<div className="input-wrapper"> */}
			{/*					<input */}
			{/*						className="input-item " */}
			{/*						type="password" */}
			{/*						placeholder="Confirm Your Password" */}
			{/*					/> */}
			{/*					<button type="button" className="show-pass"> */}
			{/*						<svg */}
			{/*							className="text-type" */}
			{/*							width="24" */}
			{/*							height="24" */}
			{/*							viewBox="0 0 24 24" */}
			{/*							fill="none" */}
			{/*							xmlns="http://www.w3.org/2000/svg" */}
			{/*						> */}
			{/*							<path */}
			{/*								d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M9.47 14.53L2 22" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M22 2L14.53 9.47" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*						</svg> */}
			{/*						<svg */}
			{/*							className="password-type" */}
			{/*							width="24" */}
			{/*							height="24" */}
			{/*							viewBox="0 0 24 24" */}
			{/*							fill="none" */}
			{/*							xmlns="http://www.w3.org/2000/svg" */}
			{/*						> */}
			{/*							<path */}
			{/*								d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*							<path */}
			{/*								d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" */}
			{/*								stroke="#777E90" */}
			{/*								strokeWidth="1.5" */}
			{/*								strokeLinecap="round" */}
			{/*								strokeLinejoin="round" */}
			{/*							/> */}
			{/*						</svg> */}
			{/*					</button> */}
			{/*				</div> */}
			{/*			</div> */}
			{/*		</div> */}
			{/*	</div> */}
			{/*	<div className="form-footer form-footer--column"> */}
			{/*		<button type="button" className="button button--full-width"> */}
			{/*			Continue */}
			{/*		</button> */}
			{/*		<button type="button" className="button button--type2 button--full-width"> */}
			{/*			Support */}
			{/*		</button> */}
			{/*	</div> */}
			{/* </div> */}
			{/*	</div> */}
			{/* </section> */}
		</>
	);
};
export default CreatePassword;
