import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { getWalletsRequest, getWalletsSuccess, walletsInitState } from './reducer';
import { IGetWalletsRequestPayloadData, IWalletsResponsePayload } from './types';

// ============================================================:
function* walletsRequestWorker({ payload }: PayloadAction<IGetWalletsRequestPayloadData>) {
	try {
		// console.log('walletsRequestWorker');
		yield put(showLoading());
		const response: IWalletsResponsePayload = yield call(api.wallets.getWallets, payload?.params);
		yield put(getWalletsSuccess(response));
	} catch (error) {
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

export function* walletsSaga() {
	yield takeEvery(getWalletsRequest.type, walletsRequestWorker);
}
