import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { capitalize, shortNotation } from 'services/utils/strings';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopyIcon } from 'assets/inline-svg';
import { statusClassNames, statusNames } from 'components/TransactionHistory/utils';
import moment from 'moment';

import NoDataIcon from 'ui/NoDataIcon/NoDataIcon';
import { ICryptoTableBodyProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const [itemFilter, setItemFilter] = useState(itemList);

	useEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}

		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard', 'info');
	};

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [date, time] = formatDateTime(item.created_at);
					return (
						<div key={item.id} className="tr">
							<div className="td td--nowrap">
								<div className="td-hidden-name">Timestamp</div>
								<div className="td__wrap">
									{item.type === 'deposit' ? (
										<>
											<div className="td__text">
												{moment.utc(item.created_at).format('YYYY-MM-DD')}
											</div>
											<div className="td__more">
												{moment.utc(item.created_at).format('HH:mm:ss')}
											</div>
										</>
									) : (
										<>
											<div className="td__text">{date}</div>
											<div className="td__more">{time}</div>
										</>
									)}
								</div>
							</div>
							{/* <div className="td td--nowrap">
								<div className="td-hidden-name">Type</div>
								<p>{capitalize(item.type)}</p>
							</div> */}
							<div className="td">
								<div className="td-hidden-name">Currency</div>
								<div className="td__wrap">
									<div className="td__text">{item.asset.code.toUpperCase()}</div>
									<div className="td__more">{item.asset.name}</div>
								</div>
							</div>
							{/* <div className="td">
								<div className="td-hidden-name">Network</div>
								<p>
									{item.chain.name} <br />
									<span className="td-more">
										{item.chain.name} Smart Chain ({item.chain.code})
									</span>
								</p>
							</div> */}
							<div className="td">
								<div className="td-hidden-name">Gross</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.gross_fee, item.asset.code)}</div>
									<div className="td__more">{item.asset.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.fee, item.asset.code)}</div>
									<div className="td__more">{item.asset.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Net</div>
								<div className="td__wrap">
									<div className="td__text">
										{toFixedNumber(item.net_fee, item.asset.code, true)}
									</div>
									<div className="td__more">{item.asset.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Destination</div>
								<div className="td__wrap">
									{item.type === 'deposit' && (
										<div className="copy-button">
											{item.destination ? shortNotation(item.destination, 8) : '-'}
											{item.destination && (
												<CopyToClipboard text={item.destination} onCopy={handleCopy}>
													<button className="copy-button-wrap" type="button">
														<svg
															width="14"
															height="14"
															viewBox="0 0 14 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M6.3332 0.533325C5.33909 0.533325 4.5332 1.33921 4.5332 2.33333V3.19999H2.3332C1.33909 3.19999 0.533203 4.00588 0.533203 4.99999V11.6667C0.533203 12.6608 1.33909 13.4667 2.3332 13.4667H7.66654C8.66065 13.4667 9.46654 12.6608 9.46654 11.6667V10.8H11.6665C12.6606 10.8 13.4665 9.9941 13.4665 8.99999V2.33333C13.4665 1.33921 12.6606 0.533325 11.6665 0.533325H6.3332ZM9.46654 9.86666H11.6665C12.1452 9.86666 12.5332 9.47864 12.5332 8.99999V2.33333C12.5332 1.85468 12.1452 1.46666 11.6665 1.46666H6.3332C5.85456 1.46666 5.46654 1.85468 5.46654 2.33333V3.19999H7.66654C8.66065 3.19999 9.46654 4.00588 9.46654 4.99999V9.86666ZM1.46654 4.99999C1.46654 4.52134 1.85456 4.13333 2.3332 4.13333H7.66654C8.14518 4.13333 8.5332 4.52135 8.5332 4.99999V11.6667C8.5332 12.1453 8.14518 12.5333 7.66654 12.5333H2.3332C1.85456 12.5333 1.46654 12.1453 1.46654 11.6667V4.99999Z"
																fill="#626A85"
															/>
														</svg>
														<span>Copy</span>
													</button>
												</CopyToClipboard>
											)}
										</div>
									)}
									{item.type === 'withdrawal' && (
										<div className="copy-button">
											{item.address.address ? shortNotation(item.address.address, 8) : '-'}
											{item.address.address && (
												<CopyToClipboard text={item.address.address} onCopy={handleCopy}>
													<button className="copy-button-wrap" type="button">
														<svg
															width="14"
															height="14"
															viewBox="0 0 14 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M6.3332 0.533325C5.33909 0.533325 4.5332 1.33921 4.5332 2.33333V3.19999H2.3332C1.33909 3.19999 0.533203 4.00588 0.533203 4.99999V11.6667C0.533203 12.6608 1.33909 13.4667 2.3332 13.4667H7.66654C8.66065 13.4667 9.46654 12.6608 9.46654 11.6667V10.8H11.6665C12.6606 10.8 13.4665 9.9941 13.4665 8.99999V2.33333C13.4665 1.33921 12.6606 0.533325 11.6665 0.533325H6.3332ZM9.46654 9.86666H11.6665C12.1452 9.86666 12.5332 9.47864 12.5332 8.99999V2.33333C12.5332 1.85468 12.1452 1.46666 11.6665 1.46666H6.3332C5.85456 1.46666 5.46654 1.85468 5.46654 2.33333V3.19999H7.66654C8.66065 3.19999 9.46654 4.00588 9.46654 4.99999V9.86666ZM1.46654 4.99999C1.46654 4.52134 1.85456 4.13333 2.3332 4.13333H7.66654C8.14518 4.13333 8.5332 4.52135 8.5332 4.99999V11.6667C8.5332 12.1453 8.14518 12.5333 7.66654 12.5333H2.3332C1.85456 12.5333 1.46654 12.1453 1.46654 11.6667V4.99999Z"
																fill="#626A85"
															/>
														</svg>
														<span>Copy</span>
													</button>
												</CopyToClipboard>
											)}
										</div>
									)}
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Tx hash</div>
								<div className="td__wrap">
									<div className="copy-button">
										{item.tx_hash ? shortNotation(item.tx_hash, 12) : '-'}
									</div>
									<div className="copy-button-info">
										{item.tx_hash && (
											<CopyToClipboard text={item.tx_hash} onCopy={handleCopy}>
												<button className="copy-button-wrap" type="button">
													<svg
														width="14"
														height="14"
														viewBox="0 0 14 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M6.3332 0.533325C5.33909 0.533325 4.5332 1.33921 4.5332 2.33333V3.19999H2.3332C1.33909 3.19999 0.533203 4.00588 0.533203 4.99999V11.6667C0.533203 12.6608 1.33909 13.4667 2.3332 13.4667H7.66654C8.66065 13.4667 9.46654 12.6608 9.46654 11.6667V10.8H11.6665C12.6606 10.8 13.4665 9.9941 13.4665 8.99999V2.33333C13.4665 1.33921 12.6606 0.533325 11.6665 0.533325H6.3332ZM9.46654 9.86666H11.6665C12.1452 9.86666 12.5332 9.47864 12.5332 8.99999V2.33333C12.5332 1.85468 12.1452 1.46666 11.6665 1.46666H6.3332C5.85456 1.46666 5.46654 1.85468 5.46654 2.33333V3.19999H7.66654C8.66065 3.19999 9.46654 4.00588 9.46654 4.99999V9.86666ZM1.46654 4.99999C1.46654 4.52134 1.85456 4.13333 2.3332 4.13333H7.66654C8.14518 4.13333 8.5332 4.52135 8.5332 4.99999V11.6667C8.5332 12.1453 8.14518 12.5333 7.66654 12.5333H2.3332C1.85456 12.5333 1.46654 12.1453 1.46654 11.6667V4.99999Z"
															fill="#626A85"
														/>
													</svg>
													<span>Copy</span>
												</button>
											</CopyToClipboard>
										)}
										{item.tx_hash && (
											// eslint-disable-next-line jsx-a11y/anchor-is-valid
											<a href="#" className="td__link">
												View Explorer
											</a>
										)}
									</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Tx ID</div>
								<div className="td__wrap">
									<div className="td__text">{item?.id}</div>
								</div>
								{/* <p className="copy-button"> */}
								{/*	<p>{item.tx_id !== null ? shortNotation(item.tx_id, 2, 2) : '-'}</p> */}
								{/*	{item.tx_id && ( */}
								{/*		<CopyToClipboard text={item.tx_id} onCopy={handleCopy}> */}
								{/*			<button type="button"> */}
								{/*				<CopyIcon /> */}
								{/*			</button> */}
								{/*		</CopyToClipboard> */}
								{/*	)} */}
								{/* </p> */}
							</div>
							<div className="td">
								<div className="td-hidden-name">Status</div>
								<div className="td__wrap">
									<div className={`td__status ${statusClassNames[item.status]}`}>
										{statusNames[item.status]}
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<NoDataIcon />
			)}
		</div>
	);
};

export default TableBody;
