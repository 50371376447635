import PrivacyPolicy from 'components/PrivacyPolicy/PrivacyPolicy';
import Dashboard from 'layouts/Dashboard';

const PrivacyPolicyPage = () => {
	return (
		<Dashboard title="Privacy Policy">
			<PrivacyPolicy />
		</Dashboard>
	);
};

export default PrivacyPolicyPage;
